import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Container = styled(Box)({
  width: '100%',
  marginX: '32px',
  borderRadius: '16px',
});

export default Container;

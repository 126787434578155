import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const FormContainer = styled(Stack)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const InputFieldsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '48px',
  marginBottom: '48px',
});

export const ReturnToSignInText = styled(Typography)({
  margin: '32px',
  textAlign: 'center',
  cursor: 'pointer',
});

export const ErrorText = styled(Typography)({
  marginBottom: '16px',
  textAlign: 'center',
});

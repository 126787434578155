import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { ConfirmationDialogProps } from '.';
import Colors from '../../theme/colors';
import {
  Container, Header, ActionsContainer, ActionButton,
} from './styles';

function ConfirmationDialog({
  onCancel,
  onConfirm,
  isOpen,
  title,
}: ConfirmationDialogProps) {
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setSubmitDisabled(false);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    setSubmitDisabled(true);
    onConfirm();
  };

  return (
    <Dialog onClose={onCancel} open={isOpen}>
      <Container>
        <Header variant="Heading4" color={Colors.Text.Heading}>
          Note
        </Header>
        <Typography variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
          {title}
        </Typography>
        <ActionsContainer>
          <ActionButton variant="outlined" onClick={onCancel}>
            No
          </ActionButton>
          <ActionButton onClick={handleSubmit} disabled={submitDisabled}>
            Yes
          </ActionButton>
        </ActionsContainer>
      </Container>
    </Dialog>
  );
}

export default ConfirmationDialog;

import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Controller } from 'react-hook-form';
import FullWidthTextField from './styles';
import { Icon } from '../Icon';
import { ValidationTextFieldProps } from '.';

function ValidationTextField({
  name,
  control,
  rules = undefined,
  onChangeInput,
  label = undefined,
  toggleShowPassword = undefined,
  autoComplete = undefined,
  disabled = undefined,
}: ValidationTextFieldProps) {
  const [showPassword, setShowPassword] = useState(toggleShowPassword);

  const onToggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const renderShowPasswordToggle = (value: string) => {
    if (value?.length) {
      const iconName = showPassword ? 'visibility-off' : 'visibility';
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={onToggleShowPassword}
            edge="end"
          >
            <Icon name={iconName} variant="active" />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  const type = toggleShowPassword && showPassword ? 'password' : 'text';
  const inputProps = (value: string) => (toggleShowPassword
    ? {
      endAdornment: renderShowPasswordToggle(value),
    }
    : undefined);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FullWidthTextField
          autoComplete={autoComplete}
          label={label}
          variant="standard"
          error={!!error?.message}
          helperText={error?.message}
          value={value}
          disabled={disabled}
          type={type}
          id={label}
          InputProps={inputProps(value)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
            if (onChangeInput) {
              onChangeInput(e.target.value);
            }
          }}
        />
      )}
    />
  );
}

export default ValidationTextField;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#ECF0FF',
  padding: ['18px', '20px'],
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  marginTop: '8px',
});

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const SectionTitle = styled(Typography)({
  marginLeft: 16,
});

const ToggleButtonContainer = styled(Box)({
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export {
  Container, TitleContainer, SectionTitle, ToggleButtonContainer,
};

export type FilterPopoverProps = {
  filterName: string;
  filterValue?: string;
  type: 'list' | 'date' | 'checkbox';
  listItems?: Array<string>;
  onListItemSelected?: (index: number) => void;
  onDatesEntered?: (startDate: Date, endDate: Date) => void;
  startDate?: Date;
  endDate?: Date;
};

export type FilterButtonProps = {
  filteractive: boolean;
};

export { default as FilterPopover } from './FilterPopover';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const LegendContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: Colors.TestResultsRowBackground,
  marginTop: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const LegendRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

const LegendLabel = styled(Typography)({
  paddingRight: '32px',
  marginBottom: '16px',
});

LegendLabel.defaultProps = {
  variant: 'BodySmallBoldOpen',
  color: Colors.Text.Heading,
};

const LegendDescription = styled(Typography)({
  paddingRight: '32px',
  paddingLeft: '16px',
});

LegendDescription.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Paragraph,
};

export {
  Container, ContentContainer, LegendContainer, LegendLabel, LegendDescription, LegendRow,
};

import Colors from '../../theme/colors';

export type ToastVariant = 'success' | 'warning' | 'error';

export type ToastProps = {
  title?: string;
  variant: 'success' | 'warning' | 'error';
  subtitle?: string;
  isOpen: boolean;
  handleClose: () => void;
};

export type VariantConfig = {
  color: string;
  iconName: string;
};

export const variantConfig = new Map<ToastVariant, VariantConfig>([
  ['success', { color: Colors.Status.Successful, iconName: 'check-circle' }],
  ['warning', { color: Colors.Status.Warning, iconName: 'info' }],
  ['error', { color: Colors.Status.Error, iconName: 'warning' }],
]);

export { default as Toast } from './Toast';

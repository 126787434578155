import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: Colors.TestResultsRowBackground,
  marginTop: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const Name = styled(Typography)({
  display: 'flex',
  flex: 39,
});

const Value = styled(Typography)({
  display: 'flex',
  flex: 61,
});

export {
  Container,
  Name,
  Value,
};

export const forgotPasswordSubmitErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'CodeMismatchException':
      return 'The code you have provided is invalid';
    case 'ExpiredCodeException':
      return 'The code you have provided has expired';
    case 'UserNotFoundException':
      return 'There is no user asocciated with this email.';
    default:
      return 'Something went wrong...';
  }
};

export const forgotPasswordErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'UserNotFoundException':
      return 'There is no user asocciated with this email.';
    default:
      return 'Something went wrong...';
  }
};

export const signInErrorMessage = (errorCode: string, errorMessage?: string) => {
  if (errorMessage === 'User is disabled.') {
    return 'This user is inactive. Please contact your administrator to activate the user.';
  }
  switch (errorCode) {
    case 'NotAuthorizedException':
    case 'UserNotFoundException':
      return 'Invalid email or password';
    default:
      return 'Something went wrong...';
  }
};

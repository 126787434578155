import React from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { formatDate } from '../../../utilities/dateTimeUtils';
import {
  HeaderTableCell,
  BodyTableCell,
  NoResults,
} from '../../../components/Table';
import ConsumersTableProps from '.';
import HeaderTableRow from './styles';

function ConsumersTable({ consumers }: ConsumersTableProps) {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <HeaderTableRow>
            <HeaderTableCell>Name</HeaderTableCell>
            <HeaderTableCell>Email</HeaderTableCell>
            <HeaderTableCell>Date Created</HeaderTableCell>
            <HeaderTableCell>Last Time Used</HeaderTableCell>
          </HeaderTableRow>
        </TableHead>
        <TableBody data-testid="consumersTableBody">
          {consumers.map((consumer) => (
            <TableRow key={consumer.userEmail}>
              <BodyTableCell>{consumer.name}</BodyTableCell>
              <BodyTableCell>{consumer.userEmail}</BodyTableCell>
              <BodyTableCell>{formatDate(consumer.dateCreated)}</BodyTableCell>
              <BodyTableCell>
                {consumer.lastTimeUsed
                  ? formatDate(consumer.lastTimeUsed)
                  : 'N/A'}
              </BodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {consumers.length ? null : <NoResults label="No consumers found." />}
    </TableContainer>
  );
}

export default ConsumersTable;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const HeaderSection = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F0F0F0',
  padding: ['18px', '20px'],

});

const SectionContainer = styled(Box)({
  marginTop: '8px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.Main.Light,
  borderColor: '#c0c0c0',
  borderRadius: '12px',
  overflow: 'hidden',
  borderWidth: '1px',
  borderStyle: 'solid',
});

export {
  Container,
  ContentContainer,
  HeaderSection,
  SectionContainer,
};

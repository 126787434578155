import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import AnswerShort from '../AnswerShort/AnswerShort';
import Icon from '../../../../components/Icon/Icon';
import {
  Container, ContentContainer, InfoMessage, InfoMessageContainer, InfoIconContainer,
} from './styles';
import { qRiskStatusInfoMessages, QRiskProps } from '.';

function QRISK({
  score10Year,
  scoreHealthy,
  heartAge,
  status,
}: QRiskProps) {
  const infoMessage = qRiskStatusInfoMessages.get(status);

  return (
    <Container>
      <SectionHeader icon="qrisk" title="QRISK3" />
      <ContentContainer>
        {infoMessage ? (
          <InfoMessageContainer>
            <InfoIconContainer>
              <Icon name="info" size={24} />
            </InfoIconContainer>
            <InfoMessage>{infoMessage}</InfoMessage>
          </InfoMessageContainer>
        ) : null}
        <AnswerShort label="Healthy Heart Age" value={heartAge ?? 'N/A'} />
        <AnswerShort label="10 Year Cardiovascular Risk" value={score10Year ? `${score10Year}%` : 'N/A'} />
        <AnswerShort label="Healthy Person Risk" value={scoreHealthy ? `${scoreHealthy}%` : 'N/A'} />
      </ContentContainer>
    </Container>
  );
}

export default QRISK;

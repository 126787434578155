import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HeaderTableCell, BodyTableCell } from '../../../../components/Table';
import SectionHeader from '../SectionHeader/SectionHeader';
import ResultDataProps from '.';
import {
  Container, ResultDataTableContainer, HeaderTableRow, ResultDataTableBody,
} from './styles';

function ResultData({ showResultData, resultData }: ResultDataProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  return (
    <Container>
      <SectionHeader
        icon="question_answer"
        title={`Result Data${showResultData ? '' : ' (Unavailable)'}`}
        expandable={showResultData}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ResultDataTableContainer>
          <Table>
            <TableHead>
              <HeaderTableRow>
                <HeaderTableCell>Values</HeaderTableCell>
                <HeaderTableCell>Triglycerides</HeaderTableCell>
                <HeaderTableCell>HDL</HeaderTableCell>
                <HeaderTableCell>TC</HeaderTableCell>
                <HeaderTableCell>Non-HDL</HeaderTableCell>
                <HeaderTableCell>TC/HDL</HeaderTableCell>
              </HeaderTableRow>
            </TableHead>

            <ResultDataTableBody data-testid="resultDataTableBody">
              {resultData.map((result) => (
                <TableRow key={result.value}>
                  <BodyTableCell>{result.value}</BodyTableCell>
                  <BodyTableCell>{result.triglycerides}</BodyTableCell>
                  <BodyTableCell>{result.hdl}</BodyTableCell>
                  <BodyTableCell>{result.totalCholesterol}</BodyTableCell>
                  <BodyTableCell>{result.nonHdl}</BodyTableCell>
                  <BodyTableCell>{result.tcHdlRatio}</BodyTableCell>
                </TableRow>
              ))}
            </ResultDataTableBody>
          </Table>
        </ResultDataTableContainer>
      ) : null}
    </Container>
  );
}

export default ResultData;

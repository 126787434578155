import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const InfoText = styled(Typography)({
  marginLeft: '8px',
});

export const IconContainer = styled(Typography)({
  width: '22px',
  height: '22px',
});

export const InfoContainer = styled(Box)({
  width: '295px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  marginBottom: '16px',
});

export const CloseButton = styled(Button)({
  marginTop: '12px',
});

export const SubmitButton = styled(Button)({
  marginTop: '16px',
});

export type User = {
  userId: string;
  name: string;
  orgName: string;
  orgId: string;
  creationTime: string;
  email: string;
  status: string;
};

export type UserRole = 'hcp' | 'admin';

export interface FullUser extends User {
  enabled: boolean;
  userStatus: string;
  orgStatus: string;
}

export type UserOrganization = {
  orgName: string;
  orgId: string;
  status: string;
};

export enum HcpToastTitles {
  ADD = 'HCP has been added.',
  EDIT = 'The HCP has been edited.',
  RESEND = 'New invitation has been sent to ',
  ERROR = 'Something went wrong...',
}

export enum AdminToastTitles {
  ADD = 'Admin has been added.',
  EDIT = 'Admin has been edited.',
  ERROR = 'Something went wrong...',
}

export type UserPageProps = {
  userRole: UserRole;
};

type UserRoleConfig = {
  roleTitle: string;
  noResultsText: string;
  addButtonTitle: string;
  addToastTitle: string;
  editToastTitle: string;
  errorToastTitle: string;
};

export const rolesConfig = new Map<UserRole, UserRoleConfig>([
  [
    'hcp',
    {
      roleTitle: 'HCP',
      noResultsText: 'No HCPs found.',
      addButtonTitle: 'Add HCP',
      addToastTitle: HcpToastTitles.ADD,
      editToastTitle: HcpToastTitles.EDIT,
      errorToastTitle: HcpToastTitles.ERROR,
    },
  ],
  [
    'admin',
    {
      roleTitle: 'Admin',
      addButtonTitle: 'Add Admin',
      noResultsText: 'No Admins found.',
      addToastTitle: AdminToastTitles.ADD,
      editToastTitle: AdminToastTitles.EDIT,
      errorToastTitle: AdminToastTitles.ERROR,
    },
  ],
]);

export { default as UserPage } from './UserPage';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const BarGraphDimensions = {
  width: 230,
};

const Container = styled(Box)({
  width: BarGraphDimensions.width,
  display: 'flex',
  height: 60,
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: -20,
});

export default Container;

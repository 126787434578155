export enum ProfileActions {
    CHANGE_NAME = 'Change name',
    CHANGE_PASSWORD = 'Change password',
    SIGN_OUT = 'Sign out',
  }

export enum ChangeNameToastTitles {
    SUCCESS = 'Your name has been changed.',
    ERROR = 'Something went wrong...'
  }

export enum ChangePasswordToastTitles {
    SUCCESS = 'Your password has been changed.',
    ERROR = 'Something went wrong...'
  }

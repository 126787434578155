import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { CircleProps } from '.';

export const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minWidth: '170px',
});

export const Circle = styled(Box)<BoxProps & CircleProps>((props) => ({
  height: '12px',
  width: '12px',
  borderRadius: '6px',
  marginRight: '8px',
  backgroundColor: props.color,
}));

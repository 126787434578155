import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../theme/colors';

const ContentContainer = styled(Box)({
  padding: '32px',
  width: '460px',
  display: 'flex',
  flexDirection: 'column',
});

const InputFieldsContainer = styled(Box)({
  marginBottom: '64px',
});

const InvitationContainer = styled(Box)({
  display: 'flex',
  height: '24px',
  marginTop: '24px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const EmailInfoContainer = styled(Box)({
  display: 'flex',
  height: '48px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '48px',
});

const EmailTextContainer = styled(Box)({
  width: '250px',
  height: '48px',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
});

const EmailText = styled(Typography)({
  overflowWrap: 'break-word',
  paddingLeft: '5px',
});

const RowLabel = styled(Typography)({});

RowLabel.defaultProps = {
  variant: 'BodyMediumRegular',
  color: Colors.Text.Paragraph,
};

export {
  ContentContainer,
  InputFieldsContainer,
  InvitationContainer,
  EmailInfoContainer,
  EmailTextContainer,
  EmailText,
  RowLabel,
};

import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';
import { HealthyStatusLabelProps, BiologicalResultContainerProps } from '.';

const BiologicalResultBackgroundColors = {
  'N/A': Colors.BiologicalResultColors.NonAvailableBackground,
  Healthy: Colors.BiologicalResultColors.HealthyBackground,
  High: Colors.BiologicalResultColors.UnhealthyBackground,
  Low: Colors.BiologicalResultColors.UnhealthyBackground,
};

const BiologicalResultBorderColors = {
  'N/A': Colors.Outline.Element,
  Healthy: Colors.Status.Successful,
  High: Colors.Status.Error,
  Low: Colors.Status.Error,
};

const Container = styled(Box)<
BoxProps & BiologicalResultContainerProps
>((props) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: ['12px', '24px'],
  margin: ['4px', '0px'],
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '88px',
  backgroundColor: BiologicalResultBackgroundColors[props.status],
  marginTop: '8px',
  borderColor: BiologicalResultBorderColors[props.status],
  borderWidth: '2px',
  borderRadius: '10px',
  borderStyle: 'solid',
}));

const TitleContainer = styled(Box)({
  flex: 39,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const TitleTextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const Title = styled(Typography)({
  marginRight: 8,
});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginRight: '16px',
  marginTop: '4px',
  alignItems: 'center',
});

const ThresholdInfo = styled(Typography)({
  marginLeft: 8,
  wordBreak: 'normal',
});

ThresholdInfo.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Paragraph,
};

const TestParameterBarContainer = styled(Box)({ flex: 33 });

const StatusContainer = styled(Typography)({
  flex: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const StatusLabelContainer = styled(Typography)<
  TypographyProps & HealthyStatusLabelProps
>((props) => ({
  width: '86px',
  height: '26px',
  backgroundColor: props.isHealthy ? Colors.Status.Successful : Colors.Status.Error,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
}));

StatusLabelContainer.defaultProps = {
  color: Colors.Main.Light,
  variant: 'BodySmallBoldPoppins',
};

export {
  Container,
  TitleContainer,
  TitleTextContainer,
  Title,
  InfoContainer,
  ThresholdInfo,
  TestParameterBarContainer,
  StatusContainer,
  StatusLabelContainer,
};

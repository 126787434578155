import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Colors from '../../../theme/colors';
import { TabContainerProps } from '.';

export const SideNavigatorTab = styled(Box)<BoxProps & TabContainerProps>((props) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: props.focused ? Colors.Main.Primary : 'rgba(0,0,0,0.0)',
  borderRadius: '8px',
  padding: '12px 16px',
}));

export const TabLabel = styled(Typography)({
  marginLeft: '16px',
});

export const ClickableTab = styled(ButtonBase)({
  width: '100%',
  height: '48px',
  marginBottom: '24px',
});

import React from 'react';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Controller } from 'react-hook-form';
import Popover from '@mui/material/Popover';
import {
  Container,
  InputContainer,
  ContentContainer,
  ErrorText,
} from './styles';
import { Icon } from '../../Icon';
import Colors from '../../../theme/colors';
import { ModalDropdownInputProps } from '.';
import usePopover from '../../../hooks/usePopover';
import { ListItemPicker } from '../../ListItemPicker';

function ModalDropdownInput({
  label,
  dropdownItems,
  selectedItem,
  onSelect,
  onEndReached,
  isLastPage,
  name,
  control,
  rules,
}: ModalDropdownInputProps) {
  const {
    anchorEl, handleClick, handleClose, open,
  } = usePopover();

  const id = open ? 'selection' : undefined;

  const handleSelect = (itemIndex: number) => {
    onSelect(itemIndex);
    handleClose();
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Container data-testid="modalDropdownInput">
          <Typography variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
            {label}
          </Typography>
          <ContentContainer>
            <ButtonBase onClick={handleClick} disableRipple>
              <InputContainer error={!!error?.message}>
                <Typography
                  variant="BodyMediumRegular"
                  color={
                    selectedItem ? Colors.Text.Paragraph : Colors.Text.Default
                  }
                >
                  {selectedItem ?? 'Select'}
                </Typography>
                <Icon
                  name={open ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
                  variant={open ? 'active' : 'default'}
                />
              </InputContainer>
            </ButtonBase>
            {error?.message ? <ErrorText>{error?.message}</ErrorText> : null}
          </ContentContainer>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <ListItemPicker
              width={248}
              onEndReached={onEndReached}
              isLastPage={isLastPage}
              values={dropdownItems}
              selectedValue={selectedItem}
              onSelect={(itemIndex) => {
                onChange(dropdownItems[itemIndex]);
                handleSelect(itemIndex);
              }}
            />
          </Popover>
        </Container>
      )}
    />
  );
}

export default ModalDropdownInput;

import React from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Colors from '../../../theme/colors';
import { Container, SwitchContainer, SwitchLabel } from './styles';
import { ModalSwitchInputProps } from '.';

function ModalSwitchInput({
  label,
  checked,
  handleChange,
  disabled,
}: ModalSwitchInputProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked);
  };

  return (
    <Container>
      <Typography variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
        {label}
      </Typography>
      <SwitchContainer>
        <SwitchLabel variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
          {checked ? 'Active' : 'Inactive'}
        </SwitchLabel>
        <Switch disabled={disabled} checked={checked} onChange={onChange} />
      </SwitchContainer>
    </Container>
  );
}

export default ModalSwitchInput;

import React, { useState } from 'react';
import { Typography } from '@mui/material';
import SectionHeader from '../SectionHeader/SectionHeader';
import {
  Container,
  ContentContainer,
  SectionContainer,
  HeaderSection,
} from './styles';
import {
  isFeedbackElementAnswer,
  isFeedbackElementSection,
  FeedbackAnswer,
} from '../..';
import AnsweredQuestion from '../AnsweredQuestions/AnsweredQuestion/AnsweredQuestion';
import Colors from '../../../../theme/colors';
import FeedbackAnswersProps from '.';

function FeedbackAnswers({ feedbackAnswers }: FeedbackAnswersProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  const renderFeedbackElement = (element: FeedbackAnswer) => {
    if (isFeedbackElementSection(element)) {
      return (
        <SectionContainer>
          <HeaderSection>
            <Typography color={Colors.Text.Heading} variant="BodyMediumBold">
              {element.sectionLabel}
            </Typography>
          </HeaderSection>
          {element.answers.map((answer) => (
            <AnsweredQuestion
              key={answer.label}
              question={answer.label}
              answer={answer.value ?? '-'}
            />
          ))}
        </SectionContainer>
      );
    }
    if (isFeedbackElementAnswer(element)) {
      return (
        <AnsweredQuestion
          key={element.label}
          question={element.label}
          answer={element.value ?? '-'}
        />
      );
    }
    return null;
  };

  return (
    <Container>
      <SectionHeader
        icon="consumers"
        title={`Feedback${feedbackAnswers.length > 0 ? '' : ' (Unavailable)'}`}
        expandable={feedbackAnswers.length > 0}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ContentContainer>
          {feedbackAnswers.map((element) => renderFeedbackElement(element))}
        </ContentContainer>
      ) : null}
    </Container>
  );
}

export default FeedbackAnswers;

import React from 'react';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import ButtonBase from '@mui/material/ButtonBase';
import { Icon } from '../Icon';
import { ToastProps, variantConfig } from '.';
import Colors from '../../theme/colors';
import {
  ContentContainer,
  DecoratorLine,
  TypeIconContainer,
  TextContentContainer,
  CloseButtonContainer,
} from './styles';

function Toast({
  variant, title, subtitle, isOpen, handleClose,
}: ToastProps) {
  const config = variantConfig.get(variant);
  const color = config?.color || Colors.Status.Pending;
  const iconName = config?.iconName || 'warning';
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={2500}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <ContentContainer>
        <DecoratorLine sx={{ backgroundColor: color }} />
        <TypeIconContainer>
          <Icon name={iconName} color={color} />
        </TypeIconContainer>
        <TextContentContainer>
          <Typography variant="BodySmallSemiBold" color={Colors.Text.Heading}>
            {title}
          </Typography>
          {subtitle ? (
            <Typography
              variant="BodyXSmallRegular"
              color={Colors.Text.Paragraph}
            >
              {subtitle}
            </Typography>
          ) : null}
        </TextContentContainer>
        <CloseButtonContainer>
          <ButtonBase onClick={() => handleClose()}>
            <Icon name="close" variant="default" />
          </ButtonBase>
        </CloseButtonContainer>
      </ContentContainer>
    </Snackbar>
  );
}

export default Toast;

import { AxiosPromise } from 'axios';
import axios from '../../utilities/axios';
import {
  GetQuestionsParams,
  GetQuestionsResponse,
} from '.';

export const QUESTION_ROUTES = {
  GET_QUESTIONS: '/question/questions-by-organization',
};

export function getQuestions(data: GetQuestionsParams):
 AxiosPromise<GetQuestionsResponse> {
  return axios.getInstance().get(`${QUESTION_ROUTES.GET_QUESTIONS}?organization_id=${data.organization_id}`);
}

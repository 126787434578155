/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../hooks/usePagination';
import { PageContainer, FiltersContainer, AddButton } from './styles';
import {
  TableFilters as UserFilters,
  defaultEmptyFilters as emptyFilters,
} from '../Table/TableFilters';
import useModal, { ModalStates } from '../../hooks/useModal';
import { UserActionModal } from './UserActionModal';
import { UsersTable } from './UsersTable';
import { Toast } from '../Toast';
import {
  formatGetUsersResponse,
  formatFilterUserParams,
  formatGetUserResponse,
  formatUpdateUserParams,
} from '../../utilities/dataTransform';
import {
  filterUsers,
  getUser,
  createUser,
  updateUser,
  resendInvitation,
} from '../../api/user/userApi';
import { FilterUsersParams } from '../../api/user';
import { OrganizationResponse } from '../../api/organization';
import {
  UserPageProps,
  User,
  FullUser,
  HcpToastTitles,
  AdminToastTitles,
  rolesConfig,
} from '.';
import useToast from '../../hooks/useToast';
import { getAdminOrganization } from '../../api/organization/organization';

function UserPage({ userRole }: UserPageProps) {
  const roleConfig = rolesConfig.get(userRole);
  const {
    initialPaginationInfo,
    paginationInfo,
    setPageCount,
    onChange,
    resetPagination,
  } = usePagination();
  const [users, setUsers] = useState<Array<User>>([]);
  const [userFilters, setUserFilters] = useState(emptyFilters);
  const [adminOrg, setAdminOrg] = useState<OrganizationResponse | undefined>(
    undefined,
  );
  const [selectedUser, setSelectedUser] = useState<FullUser | undefined>(
    undefined,
  );

  const { toastConfig, closeToast, setToastConfig } = useToast();

  const {
    modalState, openAddModal, openEditModal, closeModal,
  } = useModal();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (userRole === 'admin') {
      getAdminOrganization().then((response) => {
        setAdminOrg(response.data);
      });
    }
  }, []);

  const fetchUsers = () => {
    const dataParams: FilterUsersParams = formatFilterUserParams(
      paginationInfo.page,
      50,
      userRole,
      userFilters,
    );
    filterUsers(dataParams).then((response) => {
      setPageCount(response.data.number_of_pages);
      setUsers(formatGetUsersResponse(response.data));
    });
  };

  const handleAddUser = (
    email: string,
    role: 'admin' | 'hcp',
    name: string,
    status: 'active' | 'inactive',
    orgId?: string,
  ) => {
    const organizationId = role === 'admin' ? adminOrg?.organization_id : orgId;
    if (organizationId) {
      createUser({
        organization_id: organizationId,
        user_email: email,
        role,
        name,
        status,
      })
        .then(() => {
          setToastConfig({
            variant: 'success',
            title: roleConfig?.addToastTitle,
            isOpen: true,
          });
          fetchUsers();
        })
        .catch(() => {
          setToastConfig({
            variant: 'error',
            title: roleConfig?.errorToastTitle,
            isOpen: true,
          });
        })
        .finally(() => {
          closeModal();
        });
    }
  };

  const handleEditUser = (
    orgId: string,
    name: string,
    status: 'active' | 'inactive',
  ) => {
    if (selectedUser) {
      updateUser(formatUpdateUserParams(orgId, name, status, selectedUser))
        .then(() => {
          setToastConfig({
            variant: 'success',
            title: roleConfig?.editToastTitle,
            isOpen: true,
          });
          fetchUsers();
        })
        .catch(() => {
          setToastConfig({
            variant: 'error',
            title: roleConfig?.errorToastTitle,
            isOpen: true,
          });
        })
        .finally(() => {
          closeModal();
        });
    }
  };

  const handleResend = (userId: string, email: string) => {
    resendInvitation(userId)
      .then(() => {
        setToastConfig({
          variant: 'success',
          title: `${HcpToastTitles.RESEND} ${email}`,
          isOpen: true,
        });
      })
      .catch(() => {
        setToastConfig({
          variant: 'error',
          title: HcpToastTitles.ERROR,
          isOpen: true,
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  const onUserInteractionFailed = () => {
    setToastConfig({
      variant: 'warning',
      title: 'Uh oh, something went wrong',
      subtitle: 'Sorry! There was a problem with your request',
      isOpen: true,
    });
    closeModal();
  };

  useEffect(() => {
    // we ignore initial calls for this useEffect and the one with paginationInfo.page dependency
    // this will be triggered once more after initial trigger and fetchUsers() will be called
    // this is done to avoid too much unnecessary calls on mount of component
    if (mounted) {
      const pageWillChange = paginationInfo.page !== initialPaginationInfo.page;
      resetPagination();
      // if paginationInfo.page useEffect doesn't trigger fetching, do it here manually.
      if (!pageWillChange) {
        fetchUsers();
      }
    } else {
      setMounted(true);
    }
  }, [userFilters]);

  useEffect(() => {
    if (mounted) {
      fetchUsers();
    }
  }, [paginationInfo.page]);

  useEffect(() => {
    if (selectedUser) {
      openEditModal();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (modalState === ModalStates.CLOSED && selectedUser) {
      setSelectedUser(undefined);
    }
  }, [modalState]);

  const onTableRowClicked = (userId: string) => {
    getUser(userId).then((response) => {
      setSelectedUser(formatGetUserResponse(response.data));
    });
  };

  return (
    <PageContainer>
      <Toast
        variant={toastConfig.variant}
        title={toastConfig.title}
        isOpen={toastConfig.isOpen}
        handleClose={closeToast}
      />
      <FiltersContainer data-testid="filtersContainer">
        <UserFilters onFiltersChanged={setUserFilters} filterType={userRole} />
        <AddButton variant="contained" onClick={openAddModal}>
          {roleConfig?.addButtonTitle}
        </AddButton>
      </FiltersContainer>
      <UsersTable
        userRole={userRole}
        users={users}
        onTableRowClicked={onTableRowClicked}
      />
      {paginationInfo.pageCount > 1 ? (
        <Pagination
          data-testid="usersPagination"
          count={paginationInfo.pageCount}
          page={paginationInfo.page}
          onChange={onChange}
          variant="outlined"
          shape="rounded"
        />
      ) : null}
      <UserActionModal
        userRole={userRole}
        onClose={closeModal}
        modalState={modalState}
        selectedUser={selectedUser}
        addUser={handleAddUser}
        editUser={handleEditUser}
        resendInvitation={handleResend}
        onUserInteractionFailed={onUserInteractionFailed}
      />
    </PageContainer>
  );
}

export default UserPage;

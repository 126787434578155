import React from 'react';
import Typography from '@mui/material/Typography';
import { ConditionalLinkProps } from '.';
import Colors from '../../theme/colors';

function ConditionalLink({
  url, successText, failText = 'N/A', variant = 'BodySmallRegular',
}: ConditionalLinkProps) {
  const openLink = () => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Typography
      sx={{ cursor: url ? 'pointer' : 'default' }}
      variant={variant}
      color={url ? Colors.Text.Link : Colors.Text.Paragraph}
      onClick={openLink}
    >
      {url ? successText : failText}
    </Typography>
  );
}

export default ConditionalLink;

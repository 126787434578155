import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme/theme';
import DashboardWrapper from './pages/DashboardWrapper/DashboardWrapper';
import ProtectedRoute from './utilities/ProtectedRoute/ProtectedRoute';
import { AuthProvider } from './hooks/useAuth';
import AuthInitializer from './utilities/AuthInitializer/AuthInitializer';

import {
  HealthCareProvidersPage,
  ManageAdminsPage,
  ConsumersPage,
  OrganizationsPage,
  TestListPage,
  LoginPage,
  ForgotPasswordPage,
} from './pages';

function App() {
  return (
    <AuthProvider>
      <AuthInitializer>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<ProtectedRoute><DashboardWrapper /></ProtectedRoute>}>
                <Route path="/" element={<TestListPage />} />
                <Route path="/organizations" element={<OrganizationsPage />} />
                <Route path="/hcps" element={<HealthCareProvidersPage />} />
                <Route path="/admins" element={<ManageAdminsPage />} />
                <Route path="/consumers" element={<ConsumersPage />} />
              </Route>
              <Route path="/forgot_password" element={<ForgotPasswordPage />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AuthInitializer>
    </AuthProvider>
  );
}

export default App;

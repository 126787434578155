export enum ForgotPasswordSteps {
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EMAIL_SENT = 'EMAIL_SENT',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SUCCESS = 'SUCCESS',
}

export type PasswordState = {
    value: string;
    showPassword: boolean;
  }

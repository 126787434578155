import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressContainer from './styles';
import { useAuth } from '../../hooks/useAuth';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  if (auth?.isAuthorized === undefined) {
    return (
      <CircularProgressContainer>
        <CircularProgress />
      </CircularProgressContainer>
    );
  }
  if (!auth?.isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute;

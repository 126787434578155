import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import lodash from 'lodash';
import { formatDateRange, stringToDate } from '../../../utilities/dateTimeUtils';
import Colors from '../../../theme/colors';
import { FilterPopover } from '../../FilterPopover';
import { Icon } from '../../Icon';
import {
  TableFiltersProps,
  defaultEmptyFilters,
  consumerEmptyFilters,
  activeStatusOptions,
  resultStatusOptions,
} from '.';
import { Container, SearchQuery, ClearFilters } from './styles';
import { checkDeepEquality } from '../../../utilities/utils';

function TableFilters({ onFiltersChanged, filterType }: TableFiltersProps) {
  const emptyFilters = filterType === 'consumer' ? consumerEmptyFilters : defaultEmptyFilters;
  const [filters, setFilters] = useState(emptyFilters);
  const [query, setQuery] = useState(emptyFilters.searchQuery);
  const filtersEntered = !checkDeepEquality(filters, emptyFilters);

  useEffect(() => {
    onFiltersChanged(filters);
  }, [filters]);

  const clearFilters = () => {
    setFilters(emptyFilters);
    setQuery(emptyFilters.searchQuery);
  };

  // Status filters

  const setActiveStatus = (newStatusIndex: number) => {
    setFilters((prev) => ({
      ...prev,
      status: activeStatusOptions[newStatusIndex],
    }));
  };

  const setResultStatus = (newStatusIndex: number) => {
    setFilters((prev) => {
      const checkedItems = prev.status !== '' ? prev.status.split(', ') : [];
      const clickedFilter = resultStatusOptions[newStatusIndex];
      const itemIndex = checkedItems.findIndex(
        (item) => item === clickedFilter,
      );
      if (itemIndex !== -1) {
        // remove filter
        checkedItems.splice(itemIndex, 1);
      } else {
        // add filter
        checkedItems.push(clickedFilter);
      }
      return {
        ...prev,
        status: checkedItems.join(', '),
      };
    });
  };

  const renderStatusFilters = () => {
    switch (filterType) {
      case 'admin':
      case 'hcp':
      case 'organization': {
        return (
          <FilterPopover
            filterName="Status"
            filterValue={filters.status}
            type="list"
            listItems={activeStatusOptions}
            onListItemSelected={setActiveStatus}
          />
        );
      }
      case 'result': {
        return (
          <FilterPopover
            filterName="Status"
            filterValue={filters.status}
            type="checkbox"
            listItems={resultStatusOptions}
            onListItemSelected={setResultStatus}
          />
        );
      }
      default:
        return null;
    }
  };

  // Time filters

  const setDateCreatedRange = (startDate: Date, endDate: Date) => {
    setFilters((prev) => ({
      ...prev,
      dateCreated: {
        start: startDate.toString(),
        end: endDate.toString(),
      },
    }));
  };

  const setDateLastUsedRange = (startDate: Date, endDate: Date) => {
    setFilters((prev) => ({
      ...prev,
      dateLastUsed: {
        start: startDate.toString(),
        end: endDate.toString(),
      },
    }));
  };

  const renderTimeFilters = () => {
    switch (filterType) {
      case 'admin':
      case 'hcp':
      case 'organization':
      case 'result': {
        return (
          <FilterPopover
            filterName="Date Created"
            type="date"
            onDatesEntered={setDateCreatedRange}
            filterValue={formatDateRange(filters.dateCreated)}
            startDate={stringToDate(filters.dateCreated.start)}
            endDate={stringToDate(filters.dateCreated.end)}
          />
        );
      }
      case 'consumer': {
        return (
          <>
            <FilterPopover
              filterName="Date Created"
              type="date"
              onDatesEntered={setDateCreatedRange}
              filterValue={formatDateRange(filters.dateCreated)}
              startDate={stringToDate(filters.dateCreated.start)}
              endDate={stringToDate(filters.dateCreated.end)}
            />
            <FilterPopover
              filterName="Last Time Used"
              type="date"
              onDatesEntered={setDateLastUsedRange}
              filterValue={formatDateRange(filters.dateLastUsed)}
              startDate={stringToDate(filters.dateLastUsed?.start)}
              endDate={stringToDate(filters.dateLastUsed?.end)}
            />
          </>
        );
      }
      default:
        return null;
    }
  };

  // Query filters

  const setSearchQuery = (searchQuery: string) => {
    setFilters((prev) => ({
      ...prev,
      searchQuery,
    }));
  };

  const debouncedRef = useRef<(newQuery: string) => void>();
  debouncedRef.current = (newQuery: string) => setSearchQuery(newQuery);
  const debouncedUpdateQueryFilter = useCallback(
    lodash.debounce((newQuery: string) => {
      if (debouncedRef?.current) {
        debouncedRef.current(newQuery);
      }
    }, 250),
    [],
  );

  useEffect(() => {
    debouncedUpdateQueryFilter(query);
  }, [query]);

  return (
    <Container>
      <SearchQuery
        label="Search"
        InputProps={{
          startAdornment: <Icon name="search" variant="default" />,
        }}
        variant="standard"
        value={query}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setQuery(e.target.value);
        }}
      />
      {renderTimeFilters()}
      {renderStatusFilters()}
      <ClearFilters
        color={filtersEntered ? Colors.Text.Link : Colors.Text.Paragraph}
        onClick={clearFilters}
        variant="BodySmallRegular"
      >
        Clear All
      </ClearFilters>
    </Container>
  );
}

export default TableFilters;

/* eslint-disable camelcase */
import React, { useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import { Container, ContentContainer } from './styles';
import AnswerShort from '../AnswerShort/AnswerShort';
import TestMetadataProps from '.';

function TestMetadata({
  metadataAnswers,
}: TestMetadataProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  return (
    <Container>
      <SectionHeader
        icon="info"
        title="Test Metadata"
        expandable
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ContentContainer>
          {metadataAnswers.map(({ label, value }) => (
            <AnswerShort
              key={label}
              label={label}
              value={value ?? '-'}
            />
          ))}
        </ContentContainer>
      ) : null}
    </Container>
  );
}

export default TestMetadata;

import ForgotPasswordPage from './ForgotPasswordPage/ForgotPasswordPage';
import HealthCareProvidersPage from './HealthCareProvidersPage/HealthCareProvidersPage';
import ManageAdminsPage from './ManageAdminsPage/ManageAdminsPage';
import ConsumersPage from './ConsumersPage/ConsumersPage';
import LoginPage from './LoginPage/LoginPage';
import OrganizationsPage from './OrganizationsPage/OrganizationsPage';
import TestListPage from './TestListPage/TestListPage';

export {
  ForgotPasswordPage,
  HealthCareProvidersPage,
  ManageAdminsPage,
  ConsumersPage,
  LoginPage,
  OrganizationsPage,
  TestListPage,
};

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Container from './styles';
import { DateRangePickerProps } from '.';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerInput } from '../../DatePickerInput';

function DateRangePicker({
  filterName,
  initialStartDate,
  initialEndDate,
  onDatesEntered,
}: DateRangePickerProps) {
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);

  useEffect(() => {
    if (
      startDate
      && endDate
      && (startDate !== initialStartDate || endDate !== initialEndDate)
    ) {
      onDatesEntered(startDate, endDate);
    }
  }, [startDate, endDate]);

  return (
    <Container>
      <DatePicker
        id="From"
        portalId={filterName}
        selected={startDate ?? undefined}
        maxDate={endDate ?? new Date()}
        onChange={(date: Date) => setStartDate(date)}
        customInput={React.createElement(DatePickerInput)}
      />
      <DatePicker
        id="To"
        portalId={filterName}
        selected={endDate ?? undefined}
        minDate={startDate ?? undefined}
        maxDate={new Date()}
        onChange={(date: Date) => setEndDate(date)}
        customInput={React.createElement(DatePickerInput)}
      />
    </Container>
  );
}

export default DateRangePicker;

import React, { useState, useContext, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import axios from '../utilities/axios';

export type User = {
  email?: string;
  userId?: string;
  orgId?: string;
  role?: string;
};
interface IAuthContext {
  user?: User;
  isAuthorized?: boolean;
  token?: string;
  sessionExpired: boolean;
  initializeAuth: () => Promise<boolean>;
  updateToken: () => Promise<boolean>;
  onSessionExpired: () => Promise<boolean>;
}

export const authContext = React.createContext<IAuthContext | null>(null);

function useProvideAuth() {
  const [user, setUser] = useState<User>();
  const [isAuthorized, setIsAuthorized] = useState<boolean|undefined>(undefined);
  const [token, setToken] = useState<string | undefined>();
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);

  useEffect(() => {
    axios.setToken(token);
  }, [token]);

  const fetchPersistedData = async () => {
    try {
      const sessionData = await Auth.currentSession();
      if (sessionData) {
        setToken(`Bearer ${sessionData.getAccessToken().getJwtToken()}`);
        const { attributes } = await Auth.currentUserInfo();
        setUser({
          email: attributes.email,
          userId: attributes['custom:user_id'],
          orgId: attributes['custom:organization_id'],
          role: attributes['custom:role'],
        });
        setIsAuthorized(true);
      }
    } catch (err) {
      setIsAuthorized(false);
    }
  };

  const updateToken = async () => {
    try {
      const data = await Auth.currentSession();
      setToken(`Bearer ${data.getAccessToken().getJwtToken()}`);
      return true;
    } catch (err) {
      return false;
    }
  };

  /**
   * When user is set to inactive or deleted, part from just signing the user out,
   * we should notify him about the reason he is logged out.
   * @returns
   */

  const onSessionExpired = async () => {
    try {
      setSessionExpired(true);
      await Auth.signOut();
      return true;
    } catch (err) {
      return false;
    }
  };

  const authListener = (data: any) => {
    switch (data.payload.event) {
      case 'signIn': {
        const { signInUserSession, attributes } = data.payload.data;
        if (
          attributes['custom:role'] === 'admin'
          || attributes['custom:role'] === 'super_admin'
        ) {
          setToken(`Bearer ${signInUserSession.accessToken.jwtToken}`);
          const userData: User = {
            email: attributes.email.toLowerCase(),
            userId: attributes['custom:user_id'],
            orgId: attributes['custom:organization_id'],
            role: attributes['custom:role'],
          };
          setUser(userData);
          setIsAuthorized(true);
          setSessionExpired(false);
        } else {
          Auth.signOut();
        }
        break;
      }
      case 'signIn_failure': {
        setSessionExpired(false);
        break;
      }
      case 'signOut':
        setToken(undefined);
        setIsAuthorized(false);
        break;
      case 'tokenRefresh_failure':
        if (isAuthorized) {
          setSessionExpired(true);
          Auth.signOut();
        }
        break;
      default:
        break;
    }
  };

  const initializeAuth = async () => {
    try {
      Auth.configure({
        Auth: {
          // REQUIRED - Amazon Cognito Region
          region: process.env.REACT_APP_COGNITO_REGION,
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId:
            process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
          // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
          authenticationFlowType:
            process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
          cookieStorage: {
            // - Cookie domain (only required if cookieStorage is provided)
            domain: process.env.REACT_APP_DOMAIN,
            // (optional) - Cookie path
            path: '/',
            // (optional) - Cookie expiration in days
            expires: 3,
            // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: 'strict',
            // (optional) - Cookie secure flag
            secure: true,
          },
        },
      });
      Hub.listen('auth', authListener);
      await fetchPersistedData();
      return true;
    } catch (err) {
      return false;
    }
  };

  return {
    user,
    isAuthorized,
    token,
    sessionExpired,
    initializeAuth,
    updateToken,
    onSessionExpired,
  };
}

type AuthProviderProps = {
  children?: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

AuthProvider.defaultProps = {
  children: undefined,
};

export const useAuth = () => useContext(authContext);

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Container = styled(Box)({
  display: 'flex',
  height: '24px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SwitchContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '-12px', // Switch component has internal padding of 12px, offseting it here
});

export const SwitchLabel = styled(Typography)({
  marginRight: '8px',
});

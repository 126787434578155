import { Control, RegisterOptions } from 'react-hook-form';

export type DropdownFieldProps = {
  error: boolean;
};

export type ModalDropdownInputProps = {
  label: string;
  dropdownItems: Array<string>;
  selectedItem?: string;
  onSelect: (itemIndex: number) => void;
  onEndReached?: () => void;
  isLastPage?: boolean;
  // ValidationProps
  name: string;
  control: Control<any, any>;
  rules?: RegisterOptions;
};

export { default as ModalDropdownInput } from './ModalDropdownInput';

import { AxiosPromise } from 'axios';
import axios from '../../utilities/axios';
import {
  CreateOrganizationParams,
  UpdateOrganizationParams,
  OrganizationResponse,
  GetOrganizationsResponse,
  FilterOrganizationsParams,
  GetOrgNameAvailabilityParams,
} from '.';

export const ORGANIZATION_ROUTES = {
  CREATE: '/organization/',
  UPDATE: '/organization/',
  FILTER: '/organization/filter',
  ADMIN_ORGANIZATION: '/organization/admin-organization',
  CHECK_AVAILABILITY: '/organization/organization-by-name',
};

export function createOrganization(data: CreateOrganizationParams):
AxiosPromise<OrganizationResponse> {
  return axios.getInstance().post(ORGANIZATION_ROUTES.CREATE, data);
}

export function updateOrganization(data: UpdateOrganizationParams) :
AxiosPromise<OrganizationResponse> {
  return axios.getInstance().patch(ORGANIZATION_ROUTES.UPDATE, data);
}

export function filterOrganizations(data: FilterOrganizationsParams) :
AxiosPromise<GetOrganizationsResponse> {
  return axios.getInstance().post(ORGANIZATION_ROUTES.FILTER, data);
}

export function getAdminOrganization() : AxiosPromise<OrganizationResponse> {
  return axios.getInstance().get(ORGANIZATION_ROUTES.ADMIN_ORGANIZATION);
}

export function getOrgNameAvailability(data: GetOrgNameAvailabilityParams) {
  const encodedOrganizationName = encodeURIComponent(data.organization_name);
  return axios.getInstance().get(`${ORGANIZATION_ROUTES.CHECK_AVAILABILITY}?organization_name=${encodedOrganizationName}`);
}

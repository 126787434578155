import React from 'react';
import Popover from '@mui/material/Popover';
import ButtonBase from '@mui/material/ButtonBase';
import { Icon } from '../Icon';
import { FilterPopoverProps } from '.';
import { FilterButton, FilterButtonText } from './styles';
import { ListItemPicker } from '../ListItemPicker';
import { DateRangePicker } from './DateRangePicker';
import { CheckboxItemPicker } from '../CheckboxItemPicker';
import usePopover from '../../hooks/usePopover';
import { formatStatusButtonString } from '../../utilities/utils';

function FilterPopover({
  filterName,
  filterValue,
  type,
  listItems,
  onListItemSelected,
  onDatesEntered,
  startDate,
  endDate,
}: FilterPopoverProps) {
  const {
    anchorEl, handleClick, handleClose, open,
  } = usePopover();

  const id = open ? filterName : undefined;

  const filterEntered = !!filterValue;

  const renderContent = () => {
    switch (type) {
      case 'list':
        return listItems && onListItemSelected ? (
          <ListItemPicker
            values={listItems}
            selectedValue={filterValue}
            onSelect={(index) => {
              onListItemSelected(index);
              handleClose();
            }}
          />
        ) : null;
      case 'checkbox':
        return listItems && onListItemSelected ? (
          <CheckboxItemPicker
            values={listItems}
            selectedValues={filterValue?.split(', ') ?? []}
            onSelect={(index) => {
              onListItemSelected(index);
              handleClose();
            }}
          />
        ) : null;
      case 'date':
        return onDatesEntered ? (
          <DateRangePicker
            filterName={filterName}
            initialStartDate={startDate}
            initialEndDate={endDate}
            onDatesEntered={(start, end) => {
              onDatesEntered(start, end);
              handleClose();
            }}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <ButtonBase onClick={handleClick} disableRipple>
        <FilterButton filteractive={filterEntered}>
          <FilterButtonText
            variant="BodyMediumRegular"
            filteractive={filterEntered}
          >
            {filterValue ? formatStatusButtonString(filterValue) : filterName}
          </FilterButtonText>
          <Icon
            name={filterEntered ? 'done' : 'keyboard-arrow-down'}
            variant={filterEntered ? 'active' : 'default'}
          />
        </FilterButton>
      </ButtonBase>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        {renderContent()}
      </Popover>
    </>
  );
}

export default FilterPopover;

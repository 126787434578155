import React from 'react';
import Typography from '@mui/material/Typography';
import Colors from '../../../theme/colors';
import { NoResultsProps } from '.';
import Container from './styles';

function NoResults({ label }: NoResultsProps) {
  return (
    <Container>
      <Typography variant="Heading4" color={Colors.Text.Disabled}>
        {label}
      </Typography>
    </Container>
  );
}

export default NoResults;

import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const HeaderTableRow = styled(TableRow)({
  backgroundColor: Colors.Main.Light,
  borderWidth: '0px',
  borderBottomWidth: '2px',
  borderStyle: 'solid',
  borderColor: Colors.Icon.Inactive,
  paddingBottom: '12px',
});

const ResultDataTableContainer = styled(TableContainer)({
  border: 'none', borderRadius: 0, margin: 0,
});

const ResultDataTableBody = styled(TableBody)({
  backgroundColor: Colors.Main.Light,
});

export {
  Container, ResultDataTableContainer, ResultDataTableBody, HeaderTableRow,
};

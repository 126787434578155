import { RegisterOptions } from 'react-hook-form';
import { ModalStates } from '../../../hooks/useModal';
import { PASSWORD_REGEX } from '../../../utilities/consts';

export type ChangePasswordModalInputRules = {
  password: RegisterOptions;
  newPassword: RegisterOptions;
  confirmPassword: RegisterOptions;
};

export type ChangePasswordModalFormFields = {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export type ChangePasswordModalProps = {
  onClose: () => void;
  modalState: ModalStates;
  onPasswordUpdated: (successful: boolean) => void;
};

export const generateChangePasswordModalInputRules = (
  getValues: (value: string) => string,
): ChangePasswordModalInputRules => ({
  password: {
    required: 'Required field',
    pattern: {
      value: PASSWORD_REGEX,
      message:
        'Password must contain a lowercase letter, an uppercase letter, a number, a special character and be at least six characters long.',
    },
  },
  newPassword: {
    required: 'Required field',
    pattern: {
      value: PASSWORD_REGEX,
      message:
        'Password must contain a lowercase letter, an uppercase letter, a number, a special character and be at least six characters long.',
    },
  },
  confirmPassword: {
    required: 'Required field',
    validate: (value) => value === getValues('newPassword') || 'The passwords do not match',
  },
});

export const defaultFieldValues: ChangePasswordModalFormFields = {
  password: '',
  newPassword: '',
  confirmPassword: '',
};

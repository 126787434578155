export type ValueLabelProps = {
  label: string;
  textColor?: string;
  backgroundColor: string;
  offsetPercentage: number;
};

export type ContainerProps = {
  leftOffset: number;
};

export type ValueContainerProps = {
  color?: string;
};

export type ValueTextProps = {
  color?: string;
};

export { default as ValueLabel } from './ValueLabel';

import React from 'react';
import Colors from '../../../../../theme/colors';
import { BarGraph } from '../../../../../components/BarGraph';
import {
  getGraphColors,
  Container,
  Title,
  BarGraphContainer,
  StatusContainer,
  StatusLabel,
} from './styles';
import { getGraphParams, getStatus } from '../../../../../utilities/bmiUtils';
import { BMIProps } from '.';

function BMI({ bmiValue }: BMIProps) {
  const status = getStatus(bmiValue);
  const { valueLabelColor, valueLabelTextColor, colors } = getGraphColors(status);
  const { graphPoints, resultLabel, resultValue } = getGraphParams(bmiValue);
  const graphValue = {
    point: resultValue,
    label: resultLabel,
    bgColor: valueLabelColor,
    labelColor: valueLabelTextColor,
  };

  return (
    <Container status={status}>
      <Title color={Colors.Text.Heading} variant="BodyMediumSemiBold">BMI</Title>
      <BarGraphContainer>
        <BarGraph
          value={graphValue}
          colors={colors}
          showFirstAndLastLabel={false}
          points={graphPoints}
        />
      </BarGraphContainer>
      <StatusContainer>
        <StatusLabel status={status}>{status}</StatusLabel>
      </StatusContainer>
    </Container>
  );
}

export default BMI;

import React from 'react';
import { ConditionalLink } from '../../../../../components/ConditionalLink';
import TestImageProps from '.';
import Container from './styles';

function TestImage({ imageName, imageUrl }: TestImageProps) {
  return (
    <Container>
      <ConditionalLink url={imageUrl} successText={imageName} failText={imageName} />
    </Container>
  );
}

export default TestImage;

import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm, Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { OrganizationPicker } from '../../OrganizationPicker';
import { ModalStates } from '../../../hooks/useModal';
import {
  ModalActionsHandler,
  ModalSwitchInput,
  ModalTextInput,
} from '../../Modal';
import {
  UserActionModalProps,
  getUserActionModalInputRules,
  hcpinfoItems,
  UserActionModalFormFields,
  defaultFieldValues,
  EMAIL_UNAVAILABLE,
} from '.';

import {
  ContentContainer,
  InputFieldsContainer,
  InvitationContainer,
  EmailInfoContainer,
  EmailTextContainer,
  EmailText,
  RowLabel,
} from './styles';
import { getEmailAvailability } from '../../../api/user/userApi';
import { Organization } from '../../../pages/OrganizationsPage';
import { UserOrganization, rolesConfig } from '../index';
import Colors from '../../../theme/colors';

function UserActionModal({
  userRole,
  modalState,
  addUser,
  editUser,
  onClose,
  selectedUser,
  resendInvitation,
  onUserInteractionFailed,
}: UserActionModalProps) {
  const userActionModalInputRules = getUserActionModalInputRules(userRole);

  const [organizations, setOrganizations] = useState<Array<Organization>>([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<
    UserOrganization | undefined
  >();
  const [emailEditable, setEmailEditable] = useState(false);

  const isOpen = modalState !== ModalStates.CLOSED;

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: defaultFieldValues,
  });

  useEffect(() => {
    switch (modalState) {
      case ModalStates.EDIT: {
        if (selectedUser) {
          setEmailEditable(false);
          const {
            orgName, orgId, orgStatus, name, email, status,
          } = selectedUser;
          setSelectedOrg({ orgName, orgId, status: orgStatus });
          reset({
            name,
            email,
            org: orgName,
            active: status === 'active',
          });
        }
        break;
      }
      case ModalStates.ADD: {
        setEmailEditable(true);
        setSelectedOrg(undefined);
        reset(defaultFieldValues);
        break;
      }
      default: {
        setOrganizations([]);
        setSubmitDisabled(false);
      }
    }
  }, [modalState]);

  const handleAddUser = (email: string, name: string, active: boolean) => {
    const organizationId = userRole === 'admin' ? '' : selectedOrg?.orgId;
    addUser(
      email,
      userRole,
      name,
      active ? 'active' : 'inactive',
      organizationId,
    );
  };

  const handleEditUser = (name: string, active: boolean) => {
    if (selectedOrg && isDirty) {
      editUser(selectedOrg.orgId, name, active ? 'active' : 'inactive');
    } else {
      onClose();
    }
  };

  const handleResendInvitation = () => {
    if (selectedUser) {
      resendInvitation(selectedUser.userId, selectedUser.email);
    }
  };

  const onSubmit = ({ name, active, email }: UserActionModalFormFields) => {
    if (modalState === ModalStates.EDIT) {
      handleEditUser(name, active);
    } else {
      handleAddUser(email, name, active);
    }
  };

  const checkEmailAvailability = (data: UserActionModalFormFields) => {
    setSubmitDisabled(true);
    if (dirtyFields.email) {
      getEmailAvailability(data.email)
        .then(() => {
          setError('email', { type: 'custom', message: EMAIL_UNAVAILABLE });
          setSubmitDisabled(false);
        })
        .catch((error) => {
          if (error.status === 404) {
            onSubmit(data);
          } else {
            onUserInteractionFailed();
          }
        });
    } else {
      onSubmit(data);
    }
  };

  const onSetOrganizations = (formattedOrganizations: Array<Organization>) => {
    setOrganizations((prev) => [...prev, ...formattedOrganizations]);
  };

  const showResendButton = modalState === ModalStates.EDIT && selectedUser?.status === 'active';
  const showOrgPicker = userRole === 'hcp';

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <ContentContainer>
        <InputFieldsContainer>
          <ModalTextInput
            label={rolesConfig.get(userRole)?.roleTitle ?? 'Name'}
            testId="nameField"
            inputProps={{
              name: 'name',
              control,
              rules: userActionModalInputRules.name,
              label: 'Name',
              autoComplete: 'new-password',
            }}
          />
          {emailEditable ? (
            <ModalTextInput
              label="Email"
              testId="emailField"
              inputProps={{
                name: 'email',
                control,
                rules: userActionModalInputRules.email,
                label: 'Address',
                autoComplete: 'new-password',
              }}
            />
          ) : (
            <EmailInfoContainer>
              <RowLabel>Email</RowLabel>
              <EmailTextContainer>
                <EmailText
                  variant="BodyMediumRegular"
                  color={Colors.Text.Disabled}
                >
                  {selectedUser?.email}
                </EmailText>
              </EmailTextContainer>
            </EmailInfoContainer>
          )}
          <OrganizationPicker
            showPicker={showOrgPicker}
            name="org"
            orgStatus="active"
            rules={userActionModalInputRules.org}
            control={control}
            label="Organization"
            organizations={organizations}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            setOrganizations={onSetOrganizations}
          />
          <Controller
            name="active"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ModalSwitchInput
                label="Status"
                checked={value}
                handleChange={onChange}
                disabled={selectedOrg?.status === 'inactive'}
              />
            )}
          />

          {showResendButton ? (
            <InvitationContainer>
              <RowLabel>Invitation</RowLabel>
              {selectedUser?.userStatus === 'FORCE_CHANGE_PASSWORD' ? (
                <ButtonBase onClick={handleResendInvitation}>
                  <Typography
                    variant="BodyMediumRegular"
                    color={Colors.Text.Link}
                  >
                    Resend
                  </Typography>
                </ButtonBase>
              ) : (
                <Typography
                  variant="BodyMediumRegular"
                  color={Colors.Text.Default}
                >
                  Accepted
                </Typography>
              )}
            </InvitationContainer>
          ) : null}
        </InputFieldsContainer>

        <ModalActionsHandler
          modalState={modalState}
          submitDisabled={submitDisabled}
          infoItems={userRole === 'hcp' ? hcpinfoItems : []}
          handleSubmit={handleSubmit(checkEmailAvailability)}
          handleClose={onClose}
        />
      </ContentContainer>
    </Dialog>
  );
}

export default UserActionModal;

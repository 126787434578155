export type Organization = {
    orgId: string;
    orgName: string;
    creationTime: string;
    status: string;
    isConsOrganization: boolean;
    orgRelation?: {orgId: string, orgName: string},
    activationCode?: string;
    nhs_config?: {
        gp_connect_support: boolean,
        nhs_support: boolean,
        nhs_email_address: string
    }
}

export enum OrganizationToastTitles {
    ADD = 'The Organization has been added.',
    EDIT = 'The Organization has been edited.',
    ERROR = 'Something went wrong...'
  }

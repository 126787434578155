import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ItemContainerProps } from '.';
import Colors from '../../theme/colors';

const Container = styled(Box)({
  maxHeight: '225px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
  alignItems: 'start',
});

const ItemContainer = styled(Box)<BoxProps & ItemContainerProps>((props) => ({
  maxHeight: '40px',
  maxWidth: '160px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '8px',
  borderRadius: '8px',
  backgroundColor: props.selected ? Colors.Main.Primary : Colors.Main.Light,
}));

const ListItemText = styled(Typography)({
  width: '100%',
  textAlign: 'left',
  paddingLeft: '8px',
  alignSelf: 'center',
});

export { Container, ItemContainer, ListItemText };

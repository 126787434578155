export type Value = {
  point: number
  label: string,
  bgColor: string,
  labelColor?: string
}

export type BarGraphProps = {
  value?: Value;
  showFirstAndLastLabel?: boolean;
  points: Array<number>;
  colors: Array<string>;
};

export { default as BarGraph } from './BarGraph';

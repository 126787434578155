import {
  BMIGraphParams,
  BMIStatus,
} from '../pages/TestListPage/TestResultModal/BiologicalResults/BMI';

const bmiGraphSignificantPoints = {
  domainStart: 13.5,
  breakpoints: [18.5, 25, 30],
  domainEnd: 35,
  domainMax: 50,
};

/**
 * Calculates BMI status based on range the provided value lies in.
 * @param value BMI value
 * @returns BMI status
 */

export const getStatus = (value: number): BMIStatus => {
  if (value < 18.5) {
    return 'Underweight';
  }
  if (value < 25) {
    return 'Healthy';
  }
  if (value < 30) {
    return 'Overweight';
  }
  return 'Obese';
};

/**
 * helper method that performs value cutoff in case provided input is out of allowed range
 * and returns props needed for UI display
 * @param bmiValue real BMI input
 * @returns {BMIGraphParams}
 */

export const getGraphParams = (bmiValue: number): BMIGraphParams => {
  const {
    domainStart, domainEnd, domainMax, breakpoints,
  } = bmiGraphSignificantPoints;
  // by default, graph is plotted from domainStart. In case the provided value is bellow it,
  // the start point is lowered as well in order to plot it.
  const startPoint = Math.min(Math.floor(bmiValue), domainStart);

  // if value is larger than domainMax, we limit it to that point
  if (bmiValue > domainMax) {
    return {
      graphPoints: [startPoint, ...breakpoints, domainMax],
      resultLabel: `> ${domainMax}`,
      resultValue: domainMax,
    };
  }
  return {
    graphPoints: [
      startPoint,
      ...breakpoints,
      // by default, graph is plotted to domainEnd. In case the provided value is above it,
      // the end point is increased as well in order to plot it.
      Math.max(Math.ceil(bmiValue), domainEnd),
    ],
    resultLabel: bmiValue.toFixed(1),
    resultValue: bmiValue,
  };
};

export type QRiskStatus = 'pending' | 'test_failed' | 'success' | 'age_or_heart_disease' | 'qrisk_failed';

export type QRiskProps = {
    score10Year: string | null;
    scoreHealthy: string | null;
    heartAge: string | null;
    publishedScore10Year?: string | null;
    publishedScoreHealthy?: string | null;
    publishedHeartAge?: string | null;
    status: QRiskStatus;
};

/**
 * Defines info messages that are to be presented for respective QRisk statuses
 */
export const qRiskStatusInfoMessages: Map<QRiskStatus, string | null> = new Map([
  ['pending', null],
  [
    'test_failed',
    'This QRISK score has been calculated without cholesterol values. To create a more accurate QRISK score, please perform another PocDoc Lipid test.',
  ],
  ['success', null],
  [
    'age_or_heart_disease',
    'QRISK can only be calculated for users aged 25-84 who have not been previously diagnosed with coronary heart disease (including angina or heart attack), stroke/transient ischemic attack or type 1 diabetes.',
  ],
  ['qrisk_failed', 'There is an issue and we have been unable to calculate your QRISK.'],
]);

import React from 'react';
import Button from '@mui/material/Button';
import EmailSentProps from '.';
import Container from './styles';

function EmailSent({
  onNextStep,
}: EmailSentProps) {
  return (
    <Container>
      <Button type="submit" variant="outlined" onClick={() => onNextStep()}>OK</Button>
    </Container>
  );
}

export default EmailSent;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { SectionLabelProps } from '.';
import Colors from '../../../theme/colors';

const Container = styled(Box)({
  width: '100%',
  marginTop: 8,
  position: 'relative',
});

const SectionLabel = styled(Typography)<
  TypographyProps & SectionLabelProps
>((props) => {
  const { leftOffset } = props;
  const translation = leftOffset !== 100 && leftOffset !== 0 ? 50 : leftOffset;
  return {
    position: 'absolute',
    left: `${leftOffset}%`,
    translate: `-${translation}%`,
  };
});

SectionLabel.defaultProps = {
  variant: 'BodySmallSemiBold',
  color: Colors.Text.Default,
};

export {
  Container, SectionLabel,
};

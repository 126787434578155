import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  ResetPasswordProps,
  generateResetPasswordInputRules,
  ResetPasswordFormFields,
  defaultFieldValues,
} from '.';
import { ValidationTextField } from '../../../components/ValidationTextField';
import {
  FormContainer, InputFieldsContainer, ErrorText, ReturnToSignInText,
} from './styles';
import Colors from '../../../theme/colors';
import { forgotPasswordSubmitErrorMessage } from '../../../utilities/errorMessageUtils';

function ResetPassword({ onNextStep, email }: ResetPasswordProps) {
  const [changePasswordError, setChangePasswordError] = useState<string | null>(
    null,
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { handleSubmit, getValues, control } = useForm({
    defaultValues: defaultFieldValues,
  });

  const resetPasswordInputRules = generateResetPasswordInputRules(getValues);
  const navigate = useNavigate();

  const onSubmit = ({ code, password }: ResetPasswordFormFields) => {
    setChangePasswordError(null);
    setSubmitDisabled(true);
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        // TODO
        onNextStep();
      })
      .catch((error: any) => {
        setSubmitDisabled(false);
        setChangePasswordError(forgotPasswordSubmitErrorMessage(error.code));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <InputFieldsContainer>
          <ValidationTextField
            name="code"
            control={control}
            rules={resetPasswordInputRules.code}
            label="Code"
            autoComplete="new-password"
          />
          <ValidationTextField
            name="password"
            control={control}
            rules={resetPasswordInputRules.password}
            label="New password"
            toggleShowPassword
            autoComplete="new-password"
          />
          <ValidationTextField
            name="repeatPassword"
            control={control}
            rules={resetPasswordInputRules.repeatPassword}
            label="Enter new password again"
            toggleShowPassword
            autoComplete="new-password"
          />
        </InputFieldsContainer>
        {changePasswordError ? (
          <ErrorText color={Colors.Status.Error} variant="BodyMediumRegular">
            {changePasswordError}
          </ErrorText>
        ) : null}
        <Button
          type="submit"
          disabled={submitDisabled}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Reset password
        </Button>
        <ReturnToSignInText
          color={Colors.Text.Paragraph}
          variant="BodyMediumSemiBold"
          onClick={() => navigate('/login')}
        >
          Return to sign in
        </ReturnToSignInText>
      </FormContainer>
    </form>
  );
}

export default ResetPassword;

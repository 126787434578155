import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { SectionProps } from '.';

const Container = styled(Box)({
  height: 8,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 4,
  overflow: 'hidden',
});

const Section = styled(Box)<BoxProps & SectionProps>((props) => ({
  backgroundColor: props.color,
  width: `${props.sectionPercentage}%`,
}));

export {
  Container, Section,
};

/* eslint-disable no-unused-expressions */
import React from 'react';
import Typography from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';
import { Icon } from '../../../../../components/Icon';
import { BarGraph } from '../../../../../components/BarGraph';
import {
  Container,
  TitleContainer,
  TitleTextContainer,
  Title,
  InfoContainer,
  ThresholdInfo,
  TestParameterBarContainer,
  StatusContainer,
  StatusLabelContainer,
} from './styles';
import {
  getResultStatus,
  BloodTestParamConfigs,
  formatValue,
  getInfoMessage,
} from '../../../../../utilities/testResultUtils';
import { BiologicalResultProps } from '.';

function BiologicalResult({
  value,
  thresholdExceeded,
  ldlNotCalculated = false,
  resultType,
  // additional prop only needed for HDL, as graph breakpoint is different for Males and Females
  isFemale = undefined,
  hadCardiovascularDisease = undefined,
}: BiologicalResultProps) {
  const {
    title, significantPoints, lowerIsHealthy, unit,
  } = BloodTestParamConfigs[resultType];
  const {
    graphMin, graphMax, domainMin, domainMax,
  } = significantPoints;

  let breakpoint = resultType === 'hdl' && isFemale ? 1.2 : significantPoints.breakpoint;

  if (hadCardiovascularDisease) {
    resultType === 'nonHdl' && (breakpoint = 2.5);
    resultType === 'ldl' && (breakpoint = 1.8);
  }

  const status = getResultStatus(
    domainMin,
    lowerIsHealthy,
    breakpoint,
    thresholdExceeded,
    value,
  );

  const graphColors = [Colors.Status.Successful, Colors.Status.Error];

  const resultColor = status === 'Healthy' ? Colors.Status.Successful : Colors.Status.Error;
  const graphValue = value
    ? {
      point: value,
      label: formatValue(domainMin, domainMax, thresholdExceeded, value),
      bgColor: resultColor,
    }
    : undefined;

  const getInfoMessageLocal = () => {
    if (resultType !== 'ldl') {
      return getInfoMessage(domainMin, domainMax, thresholdExceeded, value);
    }
    return ldlNotCalculated
      ? 'Not calculated - Input value outside of limit of quantification'
      : '';
  };

  const infoMessage = getInfoMessageLocal();

  return (
    <Container status={status}>
      <TitleContainer>
        <TitleTextContainer>
          <Title color={Colors.Text.Heading} variant="BodyMediumSemiBold">
            {title}
          </Title>
          {unit ? (
            <Typography color={Colors.Text.Heading} variant="BodyMediumRegular">
              {unit}
            </Typography>
          ) : null}
        </TitleTextContainer>
        {infoMessage ? (
          <InfoContainer>
            <Icon name="info" color={Colors.Main.Dark} size={16} />
            <ThresholdInfo>{infoMessage}</ThresholdInfo>
          </InfoContainer>
        ) : null}
      </TitleContainer>
      <TestParameterBarContainer>
        <BarGraph
          value={graphValue}
          colors={graphColors}
          points={lowerIsHealthy ? [
            graphMin, breakpoint, graphMax]
            // if higher values represent healthy range, points are inverted to display
            // max value on the left hand side
            : [graphMax, breakpoint, graphMin]}
        />
      </TestParameterBarContainer>
      <StatusContainer>
        {status === 'N/A' ? (
          <Typography color={Colors.Main.Dark} variant="BodyMediumBold">
            {status}
          </Typography>
        ) : (
          <StatusLabelContainer isHealthy={status === 'Healthy'}>
            {status}
          </StatusLabelContainer>
        )}
      </StatusContainer>
    </Container>
  );
}

export default BiologicalResult;

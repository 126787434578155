import React, { useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import TestImage from './TestImage/TestImage';
import TestImagesProps from '.';
import { Container, ContentContainer } from './styles';

function TestImages({ images }: TestImagesProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  // @ts-ignore
  const imagesArray = Object.keys(images).map(((key) => [key, images[key]]));

  const imageProps = [
    { key: 'original', label: 'Original (Input) Image' },
    { key: 'colorCorrected', label: 'Color Corrected' },
    { key: 'final', label: 'Final' },
    { key: 'inputCropped', label: 'Input Cropped' },
    { key: 'shadeCorrected', label: 'Shade Corrected' },
    { key: 'whiteBalanced', label: 'White Balanced' },
    { key: 'linearized', label: 'Linearized' },
    { key: 'rois', label: 'Rois' },
    { key: 'barcodesAndRois', label: 'Barcodes&Rrois' },
    { key: 'sitesBinaryMask', label: 'Sites Binary Mask' },
    { key: 'levelsAdjusted', label: 'Levels Adjusted' },
  ];

  const availableImages = imageProps
    .map((image) => {
      const currentImage = imagesArray.find((item) => item[0] === image.key);
      // @ts-ignore
      const imageUrl = currentImage[1] ? currentImage[1].s3_url : null;
      // @ts-ignore
      const order = currentImage[1] ? currentImage[1].order : null;
      return imageUrl ? { ...image, imageUrl, order } : null;
    })
    .filter(Boolean)
    // @ts-ignore
    .sort((a, b) => a.order - b.order);
  const totalImages = availableImages.length;

  return (
    <Container>
      <SectionHeader
        icon="photo"
        title={`Test Images${images.original ? '' : ' (Unavailable)'}`}
        expandable={!!images.original}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      <ContentContainer>
        {expanded ? (
          <>
            <TestImage key={availableImages[0]?.key} imageName={`${availableImages[0]?.label}`} imageUrl={availableImages[0]?.imageUrl} />
            {availableImages.filter((image) => image?.order !== 0).map((image, index) => <TestImage key={image?.key} imageName={`Image ${index + 1}/${totalImages - 1} - ${image?.label}`} imageUrl={image?.imageUrl} />)}
          </>
        ) : null}
      </ContentContainer>
    </Container>
  );
}

export default TestImages;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const PageContainer = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LogoContainer = styled(Box)({
  width: '100%',
  marginTop: '64px',
  marginBottom: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FormContainer = styled(Stack)({
  width: '450px',
  padding: '32px',
});

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  ChangePasswordModalProps,
  generateChangePasswordModalInputRules,
  ChangePasswordModalFormFields,
  defaultFieldValues,
} from '.';
import { ModalTextInput, ModalActionsHandler } from '../../../components/Modal';
import { ModalStates } from '../../../hooks/useModal';
import { ContentContainer, ErrorText } from './styles';
import Colors from '../../../theme/colors';

function ChangePasswordModal({
  onClose,
  modalState,
  onPasswordUpdated,
}: ChangePasswordModalProps) {
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: defaultFieldValues,
  });

  const changePasswordModalInputRules = generateChangePasswordModalInputRules(getValues);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [wrongPasswordError, setWrongPasswordError] = useState<
    string | undefined
  >(undefined);

  const handleClose = () => {
    reset(defaultFieldValues);
    setSubmitDisabled(false);
    setWrongPasswordError(undefined);
    clearErrors();
  };

  const onSubmit = ({
    password,
    confirmPassword,
  }: ChangePasswordModalFormFields) => {
    setWrongPasswordError(undefined);
    setSubmitDisabled(true);
    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, password, confirmPassword))
      .then(() => {
        onPasswordUpdated(true);
      })
      .catch((err) => {
        if (err.message === 'Incorrect username or password.') {
          setWrongPasswordError('Current password incorrect');
        } else {
          onPasswordUpdated(false);
        }
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  return (
    <Dialog
      onClose={onClose}
      open={modalState === ModalStates.EDIT}
      TransitionProps={{
        onExited: () => {
          handleClose();
        },
      }}
    >
      <ContentContainer>
        <ModalTextInput
          label="Current password"
          inputProps={{
            name: 'password',
            control,
            rules: changePasswordModalInputRules.password,
            label: 'Password',
            autoComplete: 'new-password',
            toggleShowPassword: true,
          }}
        />
        <ModalTextInput
          label="New password"
          inputProps={{
            name: 'newPassword',
            control,
            rules: changePasswordModalInputRules.newPassword,
            label: 'Password',
            autoComplete: 'new-password',
            toggleShowPassword: true,
          }}
        />
        <ModalTextInput
          label="Confirm password"
          inputProps={{
            name: 'confirmPassword',
            control,
            rules: changePasswordModalInputRules.confirmPassword,
            label: 'Password',
            autoComplete: 'new-password',
            toggleShowPassword: true,
          }}
        />
        {wrongPasswordError ? (
          <ErrorText color={Colors.Status.Error} variant="BodyMediumRegular">
            {wrongPasswordError}
          </ErrorText>
        ) : null}
        <ModalActionsHandler
          modalState={modalState}
          infoItems={[]}
          submitDisabled={submitDisabled}
          handleSubmit={handleSubmit(onSubmit)}
          handleClose={onClose}
        />
      </ContentContainer>
    </Dialog>
  );
}

export default ChangePasswordModal;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import axios from '../axios';

function AuthInitializer({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  useEffect(() => {
    auth?.initializeAuth();
  }, []);

  useEffect(() => {
    if (auth?.updateToken) {
      axios.setUpdateTokenMethod(auth.updateToken);
    }
  }, [auth?.updateToken]);

  useEffect(() => {
    if (auth?.onSessionExpired) {
      axios.setSignOutUserMethod(auth.onSessionExpired);
    }
  }, [auth?.onSessionExpired]);

  return children;
}

export default AuthInitializer;

import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../theme/colors';
import { DropdownFieldProps } from '.';

const Container = styled(Box)({
  display: 'flex',
  height: '48px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '48px',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const InputContainer = styled(Box)<BoxProps & DropdownFieldProps>((props) => ({
  width: '250px',
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  borderBottom: props.error ? '2px solid' : '1px solid',
  borderColor: props.error ? Colors.Status.Error : Colors.Outline.Element,
  cursor: 'pointer',
  padding: '0px 12px 8px 8px',
}));

const ErrorText = styled(Typography)({
  textAlign: 'start',
  marginTop: '5px',
});

ErrorText.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Status.Error,
};

export {
  Container, ContentContainer, InputContainer, ErrorText,
};

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Colors from '../../theme/colors';

const ContentContainer = styled(Box)({
  height: '75px',
  width: '350px',
  display: 'flex',
  flexDirection: 'row',
  padding: '10px 8px',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: Colors.Main.Light,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: Colors.Outline.Portal,
});

const DecoratorLine = styled(Box)({
  height: '100%', width: '4px', borderRadius: '12px',
});

const TypeIconContainer = styled(Box)({ margin: '0px 18px' });

const TextContentContainer = styled(Box)({
  display: 'flex', flex: 1, flexDirection: 'column',
});

const CloseButtonContainer = styled(Box)({
  marginLeft: '16px', marginRight: '8px',
});

export {
  ContentContainer,
  DecoratorLine,
  TypeIconContainer,
  TextContentContainer,
  CloseButtonContainer,
};

import { Control, RegisterOptions } from 'react-hook-form';

export type ValidationTextFieldProps = {
  name: string;
  control: Control<any, any>;
  rules?: RegisterOptions;
  onChangeInput?: Function;
  label?: string;
  value?: string;
  toggleShowPassword?: boolean;
  autoComplete?: string;
  disabled?: boolean;
};

export { default as ValidationTextField } from './ValidationTextField';

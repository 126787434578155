import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const InfoMessageContainer = styled(Box)({
  flexDirection: 'row',
  padding: 16,
  backgroundColor: Colors.TestResultsRowBackground,
  display: 'flex',
  alignItems: 'center',
});

const InfoIconContainer = styled(Box)({ marginRight: 16, justifyContent: 'center', alignItems: 'center' });

const InfoMessage = styled(Typography)({
  display: 'flex',
  flex: 1,
});

InfoMessage.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Heading,
};

export {
  Container,
  ContentContainer,
  InfoMessage,
  InfoMessageContainer,
  InfoIconContainer,
};

import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { ContentContainer, CircularProgressContainer } from './styles';
import { getQuestions } from '../../../api/question/questionApi';
import { QuestionsModalProps } from '.';

function QuestionsModal({ onClose, organizationId }: QuestionsModalProps) {
  const [questions, setQuestions] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (organizationId) {
      getQuestions({ organization_id: organizationId })
        .then((response) => setQuestions(JSON.stringify(response.data, null, 2)))
        .catch(() => {
          setQuestions('no questions found');
        });
    } else {
      setQuestions(undefined);
    }
  }, [organizationId]);

  return (
    <Dialog
      onClose={onClose}
      open={!!organizationId}
      fullWidth
      maxWidth={questions ? 'lg' : 'sm'}
    >
      {questions ? (
        <ContentContainer>
          <pre>{questions}</pre>
        </ContentContainer>
      ) : (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      )}
    </Dialog>
  );
}

export default QuestionsModal;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../hooks/usePagination';
import { PageContainer, FiltersContainer } from './styles';
import {
  TableFilters as ConsumersFilters,
  consumerEmptyFilters as emptyFilters,
} from '../../components/Table/TableFilters';
import ConsumersTable from './ConsumersTable/ConsumersTable';
import {
  formatGetConsumersResponse,
  formatGetConsumersParams,
} from '../../utilities/dataTransform';
import { getConsumers } from '../../api/user/userApi';
import { GetConsumersParams } from '../../api/user';
import { Consumer } from '.';

function ConsumersPage() {
  const {
    initialPaginationInfo,
    paginationInfo,
    setPageCount,
    onChange,
    resetPagination,
  } = usePagination();

  const [consumers, setConsumers] = useState<Array<Consumer>>([]);
  const [consumerFilters, setConsumerFilters] = useState(emptyFilters);

  const [mounted, setMounted] = useState(false);

  const fetchConsumers = () => {
    const dataParams: GetConsumersParams = formatGetConsumersParams(
      paginationInfo.page,
      50,
      consumerFilters,
    );
    getConsumers(dataParams).then((response) => {
      setPageCount(response.data.number_of_pages);
      setConsumers(formatGetConsumersResponse(response.data));
    });
  };

  useEffect(() => {
    // we ignore initial calls for this useEffect and the one with paginationInfo.page dependency
    // this will be triggered once more after initial trigger and fetchUsers() will be called
    // this is done to avoid too much unnecessary calls on mount of component
    if (mounted) {
      const pageWillChange = paginationInfo.page !== initialPaginationInfo.page;
      resetPagination();
      // if paginationInfo.page useEffect doesn't trigger fetching, do it here manually.
      if (!pageWillChange) {
        fetchConsumers();
      }
    } else {
      setMounted(true);
    }
  }, [consumerFilters]);

  useEffect(() => {
    if (mounted) {
      fetchConsumers();
    }
  }, [paginationInfo.page]);

  return (
    <PageContainer>
      <FiltersContainer data-testid="filtersContainer">
        <ConsumersFilters
          onFiltersChanged={setConsumerFilters}
          filterType="consumer"
        />
      </FiltersContainer>
      <ConsumersTable consumers={consumers} />
      {paginationInfo.pageCount > 1 ? (
        <Pagination
          data-testid="consumersPagination"
          count={paginationInfo.pageCount}
          page={paginationInfo.page}
          onChange={onChange}
          variant="outlined"
          shape="rounded"
        />
      ) : null}
    </PageContainer>
  );
}

export default ConsumersPage;

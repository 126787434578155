import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxItemPickerProps } from '.';
import Colors from '../../theme/colors';
import { formatStatus } from '../../utilities/utils';
import { Container, ListItemText, ItemContainer } from './styles';

function CheckboxItemPicker({
  values,
  selectedValues,
  onSelect,
  width,
}: CheckboxItemPickerProps) {
  return (
    <Container
      width={width}
      id="scrollableDiv"
      data-testid="checkboxItemPicker"
    >
      {values.map((listItem, index) => (
        <ButtonBase
          key={listItem}
          onClick={() => onSelect(index)}
          disableRipple
        >
          <ItemContainer>
            <Checkbox checked={selectedValues.includes(listItem)} />

            <ListItemText
              variant="BodySmallRegular"
              color={
                selectedValues.includes(listItem)
                  ? Colors.Text.Link
                  : Colors.Text.Default
              }
            >
              {formatStatus(listItem)}
            </ListItemText>
          </ItemContainer>
        </ButtonBase>
      ))}
    </Container>
  );
}

export default CheckboxItemPicker;

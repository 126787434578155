import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../../theme/colors';
import { ContainerProps, ValueContainerProps, ValueTextProps } from '.';

const Container = styled(Box)<BoxProps & ContainerProps>((props) => ({
  width: 'fit-content',
  flexDirection: 'column',
  marginBottom: -4,
  marginLeft: `${props.leftOffset}%`,
  translate: '-50%',
  zIndex: 2,
}));

const ValueContainer = styled(Box)<BoxProps & ValueContainerProps>((props) => ({
  height: 27,
  backgroundColor: props.color,
  borderRadius: 4,
}));

const PositionIndicator = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const ValueText = styled(Typography)<TypographyProps & ValueTextProps>(
  (props) => ({
    color: props.color ?? Colors.Main.Light,
    padding: ['0px', '8px'],
    whiteSpace: 'nowrap',
  }),
);

ValueText.defaultProps = {
  variant: 'BodyMediumBold',
};

export {
  Container, ValueContainer, PositionIndicator, ValueText,
};

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';

const ButtonWrapper = styled(ButtonBase)({
  width: '100%', cursor: 'pointer',
});

const DatePickerTextField = styled(TextField)({
  width: '100%', marginTop: '24px',
});

export { ButtonWrapper, DatePickerTextField };

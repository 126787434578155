import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '48px',
});

export const InputContainer = styled(Box)({
  width: '250px',
});

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ContentContainer = styled(Box)({
  padding: '32px',
  width: '460px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
});

const ErrorText = styled(Typography)({
  marginBottom: '16px',
  textAlign: 'center',
});

export { ContentContainer, ErrorText };

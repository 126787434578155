import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Colors from '../../../../../theme/colors';

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  padding: '24px',
  backgroundColor: Colors.TestResultsRowBackground,
  marginTop: '8px',
});

export default Container;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
export {
  Container,
  ContentContainer,
};

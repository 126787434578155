export type ColorBarProps = {
  sections: Array<number>;
  colors: Array<string>;
};

export type SectionProps = {
  sectionPercentage: number;
  color: string;
};

export { default as ColorBar } from './ColorBar';

import { RegisterOptions } from 'react-hook-form';
import { ModalStates } from '../../../hooks/useModal';
import { NO_SPACE_ON_START_OR_END } from '../../../utilities/consts';

type ChangeNameModalInputRules = {
  name: RegisterOptions;
};

export type ChangeNameFormFields = {
  name: string;
}

export type ChangeNameModalProps = {
  onClose: () => void;
  modalState: ModalStates;
  onNameUpdated: (successful : boolean) => void;
};

export const editProfileModalInputRules: ChangeNameModalInputRules = {
  name: {
    required: 'Required field',
    pattern: {
      value: NO_SPACE_ON_START_OR_END,
      message: "Admin's name can't start or end with a space",
    },
    minLength: {
      value: 2,
      message: 'Name must contain min 2 and max 50 characters',
    },
    maxLength: {
      value: 50,
      message: 'Name must contain min 2 and max 50 characters',
    },
  },
};

export const defaultFieldValues: ChangeNameFormFields = {
  name: '',
};

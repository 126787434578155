import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logo.svg';
import Colors from '../../theme/colors';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import EmailSent from './EmailSent/EmailSent';
import ResetPassword from './ResetPassword/ResetPassword';
import Success from './Success/Success';
import { PageContainer, FormContainer, LogoContainer } from './styles';
import { ForgotPasswordSteps } from './index';

function ForgotPasswordPage() {
  const [currentStep, setCurrentStep] = useState(ForgotPasswordSteps.FORGOT_PASSWORD);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const getMaskedEmail = (originalEmail: string) => {
    const words = originalEmail.split('@');
    if (words.length === 2) {
      const masked = `${words[0].charAt(0)}***@${words[1].charAt(0)}***`;
      return masked;
    }
    return 'address.';
  };

  const renderTitle = () => {
    switch (currentStep) {
      case ForgotPasswordSteps.FORGOT_PASSWORD:
        return 'Forgot your password?';
      case ForgotPasswordSteps.EMAIL_SENT:
        return 'Email sent';
      case ForgotPasswordSteps.RESET_PASSWORD:
        return 'Reset password';
      case ForgotPasswordSteps.SUCCESS:
        return 'Password has been set';
      default:
        return '';
    }
  };

  const renderSubtitle = () => {
    switch (currentStep) {
      case ForgotPasswordSteps.FORGOT_PASSWORD:
        return 'Enter your email and we will send you a message to reset your password.';
      case ForgotPasswordSteps.EMAIL_SENT:
        return 'We sent you an email with a code to reset your password.';
      case ForgotPasswordSteps.RESET_PASSWORD:
        return `We have sent a password reset code by email to ${getMaskedEmail(email)}`;
      case ForgotPasswordSteps.SUCCESS:
        return 'You can sign in with your new password now';
      default:
        return '';
    }
  };

  const onNextStep = () => {
    switch (currentStep) {
      case ForgotPasswordSteps.FORGOT_PASSWORD:
        setCurrentStep(ForgotPasswordSteps.EMAIL_SENT);
        break;
      case ForgotPasswordSteps.EMAIL_SENT:
        setCurrentStep(ForgotPasswordSteps.RESET_PASSWORD);
        break;
      case ForgotPasswordSteps.RESET_PASSWORD:
        setCurrentStep(ForgotPasswordSteps.SUCCESS);
        break;
      case ForgotPasswordSteps.SUCCESS:
        navigate('/login');
        break;
      default:
        break;
    }
  };

  const onConfirmationCodeSent = (emailAddress: string) => {
    setEmail(emailAddress);
    onNextStep();
  };

  const getStepComponent = () => {
    switch (currentStep) {
      case ForgotPasswordSteps.FORGOT_PASSWORD:
        return <ForgotPassword onConfirmationCodeSent={onConfirmationCodeSent} />;
      case ForgotPasswordSteps.EMAIL_SENT:
        return <EmailSent onNextStep={onNextStep} />;
      case ForgotPasswordSteps.RESET_PASSWORD:
        return <ResetPassword onNextStep={onNextStep} email={email} />;
      case ForgotPasswordSteps.SUCCESS:
        return <Success onNextStep={onNextStep} />;
      default:
        return null;
    }
  };

  const title = renderTitle();
  const subtitle = renderSubtitle();

  return (
    <PageContainer>
      <FormContainer>
        <LogoContainer m="auto">
          <Logo width={200} height={58} />
        </LogoContainer>
        <Typography color={Colors.Text.Heading} variant="Heading3">{title}</Typography>
        <Typography color={Colors.Text.Paragraph} mt={1} variant="BodyMediumRegular">{subtitle}</Typography>
        {getStepComponent()}
      </FormContainer>
    </PageContainer>

  );
}

export default ForgotPasswordPage;

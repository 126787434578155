type FontStyle = {
  fontFamily: string,
  fontWeight: number,
  fontSize: string,
  lineHeight: string
}

interface FontType {
    [key: string]: FontStyle
 }

const Heading: FontType = {
  Heading1: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '42px',
    lineHeight: '57px',
  },
  Heading2: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '35px',
    lineHeight: '48px',
  },
  Heading3: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '29px',
    lineHeight: '39px',
  },
  Heading4: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '33px',
  },
} as const;

const Large: FontType = {
  Regular: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '23px',
  },
  SemiBold: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '23px',
  },
  Bold: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '23px',
  },
} as const;

const Medium: FontType = {
  Bold: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19px',
  },
  SemiBold: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
  },
  Regular: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
  },
} as const;

const Small: FontType = {
  SemiBold: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  Regular: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  BoldPoppins: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
  },
  BoldOpen: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
} as const;

const XSmall: FontType = {
  Regular: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '14px',
  },
  ExtraBold: {
    fontFamily: 'Open Sans',
    fontWeight: 800,
    fontSize: '10px',
    lineHeight: '14px',
  },
} as const;

const Body = {
  Large,
  Medium,
  Small,
  XSmall,
} as const;

const FontStyles = {
  Heading,
  Body,
};

export default FontStyles;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CircularProgressContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

export default CircularProgressContainer;

import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { ListItemPickerProps } from '.';
import Colors from '../../theme/colors';
import { Container, ListItemText, InfiniteScrollWrapper } from './styles';

// TODO separate infinite scroll and normal lists?
function ListItemPicker({
  values,
  selectedValue,
  onSelect,
  width,
  onEndReached,
  isLastPage,
}: ListItemPickerProps) {
  const renderItems = () => values.map((listItem, index) => (
    <ButtonBase key={listItem} onClick={() => onSelect(index)} disableRipple>
      <ListItemText
        variant="BodySmallRegular"
        color={
            listItem === selectedValue ? Colors.Text.Link : Colors.Text.Default
          }
      >
        {listItem}
      </ListItemText>
    </ButtonBase>
  ));

  const isInfiniteScrollList = onEndReached !== undefined && isLastPage !== undefined;

  return (
    <Container width={width} id="scrollableDiv" data-testid="listItemPicker">
      {isInfiniteScrollList ? (
        <InfiniteScrollWrapper
          scrollableTarget="scrollableDiv"
          next={onEndReached}
          dataLength={values.length}
          hasMore={!isLastPage}
          loader={(
            <ListItemText
              variant="BodySmallRegular"
              color={Colors.Text.Disabled}
            >
              Loading...
            </ListItemText>
          )}
        >
          {renderItems()}
        </InfiniteScrollWrapper>
      ) : (
        renderItems()
      )}
    </Container>
  );
}

export default ListItemPicker;

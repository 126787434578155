import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import ButtonBase from '@mui/material/ButtonBase';
import { Auth } from 'aws-amplify';
import {
  PageContainer,
  SideNavigator,
  Divider,
  ContentContainer,
  ProfileActionsContainer,
  ProfileIconContainer,
  PageTitle,
} from './styles';
import DashboardTab from './DashboardTab/DashboardTab';
import Colors from '../../theme/colors';
import { Icon } from '../../components/Icon';
import {
  getDashboardRoutesForRole,
  getLabelForRoute,
} from '../../utilities/dashboardRoutes';
import { useAuth } from '../../hooks/useAuth';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import usePopover from '../../hooks/usePopover';
import { ListItemPicker } from '../../components/ListItemPicker';
import { Toast } from '../../components/Toast';
import {
  ProfileActions,
  ChangeNameToastTitles,
  ChangePasswordToastTitles,
} from '.';
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';
import ChangeNameModal from './ChangeNameModal/ChangeNameModal';
import useModal from '../../hooks/useModal';
import useToast from '../../hooks/useToast';

function DashboardWrapper() {
  const {
    anchorEl, handleClick, handleClose, open,
  } = usePopover();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const {
    modalState: passwordModalState,
    openEditModal: openPasswordModal,
    closeModal: closePasswordModal,
  } = useModal();

  const {
    modalState: nameModalState,
    openEditModal: openNameModal,
    closeModal: closeNameModal,
  } = useModal();

  const { toastConfig, closeToast, setToastConfig } = useToast();

  const [pageTitle, setPageTitle] = useState(
    getLabelForRoute(location.pathname),
  );
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

  const DashboardRoutesForRole = getDashboardRoutesForRole(auth?.user?.role);

  const onTabClicked = (route: string, label: string) => {
    setPageTitle(label);
    navigate(route, { replace: true });
  };
  const profileIconVariant = open ? 'active' : 'default';
  const profilePopoverListValues = [
    ProfileActions.CHANGE_NAME,
    ProfileActions.CHANGE_PASSWORD,
    ProfileActions.SIGN_OUT,
  ];

  const onProfileAction = (index: number) => {
    switch (profilePopoverListValues[index]) {
      case ProfileActions.CHANGE_NAME: {
        handleClose();
        openNameModal();
        break;
      }
      case ProfileActions.CHANGE_PASSWORD: {
        handleClose();
        openPasswordModal();
        break;
      }
      case ProfileActions.SIGN_OUT: {
        handleClose();
        setSignOutDialogOpen(true);
        break;
      }
      default: {
        handleClose();
        break;
      }
    }
  };

  const signOutDialogCancel = () => {
    setSignOutDialogOpen(false);
  };

  const signOutDialogConfirm = () => {
    Auth.signOut().then(() => {
      setSignOutDialogOpen(false);
    });
  };

  const onNameUpdated = (success: boolean) => {
    closeNameModal();
    setToastConfig({
      variant: success ? 'success' : 'error',
      title: success
        ? ChangeNameToastTitles.SUCCESS
        : ChangeNameToastTitles.ERROR,
      isOpen: true,
    });
  };

  const onPasswordUpdated = (success: boolean) => {
    closePasswordModal();
    setToastConfig({
      variant: success ? 'success' : 'error',
      title: success
        ? ChangePasswordToastTitles.SUCCESS
        : ChangePasswordToastTitles.ERROR,
      isOpen: true,
    });
  };

  return (
    <PageContainer>
      <SideNavigator data-testid="side-navigator">
        <Typography color={Colors.Main.Light} variant="BodyLargeBold">
          PocDoc AP
        </Typography>
        <Divider />
        {DashboardRoutesForRole.map((dashboardRoute) => (
          <DashboardTab
            key={dashboardRoute.route}
            route={dashboardRoute.route}
            iconName={dashboardRoute.iconName}
            label={dashboardRoute.label}
            focused={location.pathname === dashboardRoute.route}
            onTabClicked={onTabClicked}
          />
        ))}
      </SideNavigator>
      <ContentContainer>
        <ProfileActionsContainer>
          <Typography color={Colors.Text.Paragraph} variant="BodyMediumRegular">
            {auth?.user?.email}
          </Typography>
          <ButtonBase onClick={handleClick} disableRipple>
            <ProfileIconContainer>
              <Icon variant={profileIconVariant} name="face" />
            </ProfileIconContainer>
          </ButtonBase>
        </ProfileActionsContainer>
        <PageTitle color={Colors.Text.Heading} variant="Heading4">
          {pageTitle}
        </PageTitle>
        <Outlet />
      </ContentContainer>
      <Popover
        id="Profile"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{ horizontal: 'right', vertical: -10 }}
      >
        <ListItemPicker
          values={profilePopoverListValues}
          onSelect={onProfileAction}
        />
      </Popover>
      <ConfirmationDialog
        isOpen={signOutDialogOpen}
        onCancel={signOutDialogCancel}
        onConfirm={signOutDialogConfirm}
        title="Are you sure you want to sign out?"
      />
      <ChangePasswordModal
        onClose={closePasswordModal}
        modalState={passwordModalState}
        onPasswordUpdated={onPasswordUpdated}
      />
      <ChangeNameModal
        onClose={closeNameModal}
        modalState={nameModalState}
        onNameUpdated={onNameUpdated}
      />
      <Toast
        variant={toastConfig.variant}
        title={toastConfig.title}
        isOpen={toastConfig.isOpen}
        handleClose={closeToast}
      />
    </PageContainer>
  );
}

export default DashboardWrapper;

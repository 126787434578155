import React, { useState, useEffect } from 'react';
import {
  InfoContainer,
  InfoText,
  CloseButton,
  SubmitButton,
  IconContainer,
} from './styles';
import Colors from '../../../theme/colors';
import { Icon } from '../../Icon';
import { ModalStates } from '../../../hooks/useModal';
import { ModalActionHandlerProps } from '.';

function ModalActionsHandler({
  modalState,
  submitDisabled,
  infoItems,
  handleSubmit,
  handleClose,
}: ModalActionHandlerProps) {
  const [submitButtonTitle, setSubmitBottonTitle] = useState('Save');

  useEffect(() => {
    switch (modalState) {
      case ModalStates.ADD: {
        setSubmitBottonTitle('Add');
        break;
      }
      case ModalStates.EDIT: {
        setSubmitBottonTitle('Save');
        break;
      }
      default:
        break;
    }
  }, [modalState]);

  return (
    <>
      {infoItems.map((infoItem, index) => (
        // eslint-disable-next-line
        <InfoContainer key={index}>
          <IconContainer>
            <Icon name="info" variant="active" />
          </IconContainer>
          <InfoText variant="BodyXSmallRegular" color={Colors.Text.Paragraph}>
            {infoItem}
          </InfoText>
        </InfoContainer>
      ))}
      <SubmitButton
        type="submit"
        variant="contained"
        onClick={handleSubmit}
        disabled={submitDisabled}
      >
        {submitButtonTitle}
      </SubmitButton>
      <CloseButton variant="outlined" onClick={handleClose}>
        Close
      </CloseButton>
    </>
  );
}

export default ModalActionsHandler;

import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/useAuth';
import {
  ChangeNameModalProps,
  ChangeNameFormFields,
  editProfileModalInputRules,
  defaultFieldValues,
} from '.';
import { getUser, updateUser } from '../../../api/user/userApi';
import { ModalTextInput, ModalActionsHandler } from '../../../components/Modal';
import { ModalStates } from '../../../hooks/useModal';
import ContentContainer from './styles';

function ChangeNameModal({
  onClose,
  modalState,
  onNameUpdated,
}: ChangeNameModalProps) {
  const auth = useAuth();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [currentName, setCurrentName] = useState('');
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFieldValues,
  });

  useEffect(() => {
    const userId = auth?.user?.userId;
    if (userId) {
      getUser(userId).then(({ data }) => {
        setCurrentName(data.name);
      });
    }
  }, []);

  useEffect(() => {
    reset({ name: currentName });
  }, [currentName]);

  const handleClose = () => {
    reset({ name: currentName });
    setSubmitDisabled(false);
    clearErrors();
  };

  const onSubmit = ({ name }: ChangeNameFormFields) => {
    setSubmitDisabled(true);
    const userId = auth?.user?.userId;
    if (isDirty && userId) {
      updateUser({ name, user_id: userId })
        .then((response) => {
          setCurrentName(response.data.name);
          onNameUpdated(true);
        })
        .catch((err) => {
          console.log(`Error updating admin username${err}`);
          onNameUpdated(false);
        });
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={modalState === ModalStates.EDIT}
      TransitionProps={{
        onExited: () => {
          handleClose();
        },
      }}
    >
      <ContentContainer>
        <ModalTextInput
          label="Admin"
          inputProps={{
            name: 'name',
            control,
            rules: editProfileModalInputRules.name,
            label: 'Name',
          }}
        />

        <ModalActionsHandler
          modalState={modalState}
          infoItems={[]}
          submitDisabled={submitDisabled}
          handleSubmit={handleSubmit(onSubmit)}
          handleClose={onClose}
        />
      </ContentContainer>
    </Dialog>
  );
}

export default ChangeNameModal;

import moment from 'moment';

type DateParam = string | Date;
export type DateRange = {
  start: string;
  end: string;
};

// formats date to DD MMM YYYY string used thought the client app
const formatDate = (date?: DateParam) => (date ? moment(date).format('DD/MM/YYYY') : undefined);

// formats date to HH:mm:ss, DD/MM/YYYY, hh:mm A string used thought the client app
const formatDateToLongFormat = (date?: DateParam) => (date ? moment(date).format('HH:mm:ss, DD/MM/YYYY') : undefined);

// formats date range to DD/MM/YY - DD/MM/YY string
const formatDateRange = (dateRange?: DateRange) => (dateRange?.start && dateRange?.end
  ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`
  : undefined);

// creates date object from provided string
const stringToDate = (dateString?: string) => (dateString ? new Date(dateString) : undefined);

// formats date to YYYY-MM-DD string used on BE
const toServerFormat = (date?: DateParam) => (date ? moment(date).format('YYYY-MM-DD') : undefined);

export {
  formatDate,
  formatDateRange,
  stringToDate,
  toServerFormat,
  formatDateToLongFormat,
};

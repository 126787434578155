import React from 'react';
import { DashboardTabProps } from './index';
import { SideNavigatorTab, TabLabel, ClickableTab } from './styles';
import { Icon } from '../../../components/Icon';
import Colors from '../../../theme/colors';

function DashboardTab({
  iconName,
  route,
  label,
  focused,
  onTabClicked,
}: DashboardTabProps) {
  return (
    <ClickableTab
      disabled={focused}
      onClick={() => {
        onTabClicked(route, label);
      }}
    >
      <SideNavigatorTab focused={focused}>
        <Icon name={iconName} variant="default" />
        <TabLabel color={Colors.Main.Light} variant="BodyMediumRegular">
          {label}
        </TabLabel>
      </SideNavigatorTab>
    </ClickableTab>
  );
}

export default DashboardTab;

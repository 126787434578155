import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PageContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  overflow: 'hidden',
});

const FiltersContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
});

export { PageContainer, FiltersContainer };

import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';
import {
  StatusLabelProps, BMIGraphColors, BMIStatus, BMIContainerProps,
} from '.';

const BMIContainedBackgroundColors = {
  Underweight: Colors.BiologicalResultColors.WarningBackground,
  Healthy: Colors.BiologicalResultColors.HealthyBackground,
  Overweight: Colors.BiologicalResultColors.WarningBackground,
  Obese: Colors.BiologicalResultColors.UnhealthyBackground,
};

export const StatusBgColor = {
  Underweight: Colors.Status.Warning,
  Healthy: Colors.Status.Successful,
  Overweight: Colors.Status.Warning,
  Obese: Colors.Main.Blood,
};

export const StatusTextColor = {
  Underweight: Colors.Main.Dark,
  Healthy: Colors.Main.Light,
  Overweight: Colors.Main.Dark,
  Obese: Colors.Main.Light,
};

export const getGraphColors = (status: BMIStatus): BMIGraphColors => ({
  valueLabelColor: StatusBgColor[status],
  valueLabelTextColor: StatusTextColor[status],
  colors: Object.values(StatusBgColor),
});

const Container = styled(Box)<
BoxProps & BMIContainerProps
>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '88px',
  padding: ['12px', '24px'],
  margin: ['4px', '0px'],
  marginTop: '8px',
  borderRadius: '10px',
  borderStyle: 'solid',
  borderWidth: '2px',
  backgroundColor: BMIContainedBackgroundColors[props.status],
  borderColor: StatusBgColor[props.status],
}));

const Title = styled(Typography)({
  flex: 39,
  display: 'flex',
  alignItems: 'center',
});

Title.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Heading,
};

const BarGraphContainer = styled(Box)({ flex: 33 });

const StatusContainer = styled(Typography)({
  flex: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const StatusLabel = styled(Typography)<
  TypographyProps & StatusLabelProps
>((props) => ({
  width: '86px',
  height: '26px',
  backgroundColor: StatusBgColor[props.status],
  color: StatusTextColor[props.status],
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
}));

StatusLabel.defaultProps = {
  variant: 'BodySmallBoldPoppins',
};

export {
  Container,
  Title,
  BarGraphContainer,
  StatusContainer,
  StatusLabel,
};

import Colors from '../theme/colors';

export const checkDeepEquality = (first: any, second: any):
 boolean => JSON.stringify(first) === JSON.stringify(second);

/**
 * Capitalizes the first letter of the input string
 * @param word input string, eg 'word'
 * @returns capitalized string, eg 'Word'
 */

export const capitalizeWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * Remove underscores from statuses
 * @param status, eg "Failed" or something like "shading_correction_failed"
 * @returns word/words separated with spaces where the first one is capitalized
 * eg "Failed" or something like "Shading correction failed"
 */
export const formatStatus = (status: string) => {
  const words = status.split('_');
  const formattedWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  }).join(' ');
  return formattedWords;
};

/**
 * @param inputString string with white spaces
 * @param replaceWith choose what to replace 2nd white space with
 * @param nthSpace chose which white space to replace
 * @returns string with replaced 2nd whitespace
 */
const replaceNthWhitespace = (inputString: string, replaceWith: string, nthSpace: number) => {
  const parts = inputString.split(' ');
  if (parts.length > 2) {
    parts[nthSpace - 1] += replaceWith;
    return parts.join(' ');
  }
  return inputString;
};

/**
 * Format PKS status
 * @param status
 * @returns
 */
export const formatPKBStatus = (status: string) => {
  const capitalizeWords = ['nhs', 'ods'];

  if (status.startsWith('not_sent')) {
    const words = status.split('_');
    const formattedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      if (capitalizeWords.includes(word.toLowerCase())) {
        return word.toUpperCase();
      }
      return word.toLowerCase();
    }).join(' ');

    return replaceNthWhitespace(formattedWords, ' -', 2);
  }
  return status;
};

/**
 * Remove underscores from the string that contains multiple strings
 * separated with comma
 * @param inputString eg "Failed, shading_correction_failed"
 * @returns string that contains multiple strings separated with comma and none contain underscore
 * eg "Failed, Shading correction failed"
 */
export const formatStatusButtonString = (inputString: string) => {
  const words = inputString.split(', ');
  const formattedWords = words.map((word) => {
    const capitalizedWord = word.replace(/^\w/, (c) => c.toUpperCase());
    const replacedUnderscores = capitalizedWord.replace(/_/g, ' ');
    return replacedUnderscores;
  });
  const formattedString = formattedWords.join(', ');
  return formattedString;
};

/**
 * Maps the email sent status circle color
 * @param status
 */
export const getEmailStatusColor = (status?: string) => {
  switch (status?.toLocaleLowerCase()) {
    case 'sent':
      return Colors.Status.Successful;
    case 'pending':
      return Colors.Status.Pending;
    case 'failed':
      return Colors.Status.Error;
    case 'not_sent':
      return Colors.Icon.Inactive;
    default:
      return '';
  }
};

/**
 * Maps the pkb sent status circle color
 * @param status
 * @returns
 */
export const getPkbStatusColor = (status?: string) => {
  switch (status) {
    case 'sent':
      return Colors.Status.Successful;
    case 'pending':
      return Colors.Status.Pending;
    case 'not_sent_ods_code_not_supported':
    case 'not_sent_nhs_number_not_found':
      return Colors.Icon.Inactive;
    case 'failed':
      return Colors.Status.Error;
    default:
      return '';
  }
};

// Test results

/**
 * Maps the status of the test result to it's respective color representation
 * @param status represents the status of the test result
 * @returns the color which represents the said status
 */
export const getStatusColor = (status: string) => {
  switch (status) {
    case 'successful':
      return Colors.Status.Successful;
    case 'failed':
    case 'shading_correction_failed':
    case 'chip_not_found':
    case 'sites_not_found':
    case 'reactions_failed':
    case 'white_balance_failed':
    case 'time_exceeded':
      return Colors.Status.Error;
    case 'warning':
      return Colors.Status.Warning;
    default:
      return Colors.Status.Pending;
  }
};
/**
 * Calculates where the value lies between min and max, percentage-wise.
 * @param min start of range
 * @param value value we are analyzing
 * @param max end of range
 * @returns where, percentage-wise is the value between min and max
 */

export const getPercentagePosition = (
  min: number,
  value: number,
  max: number,
) => (100 / (max - min)) * (value - min);

/**
 * @param points array of points, e.g. [1,5,11]
 * @returns proportion of distances between subsequent points, in percentages e.g. [40, 60]
 */

export const getDistanceInPercentages = (points: Array<number>): Array<number> => {
  const totalDistance = points[points.length - 1] - points[0];
  // remove first point, since it has no point before it to calculate distance from
  const percentages = points.slice(1).map((point, i) => {
    const distanceFromPreviousPoint = point - points[i];
    return (100 / totalDistance) * distanceFromPreviousPoint;
  });
  return percentages;
};

/**
 * @param points array of points
 * @param point value we are analysing
 * @returns percentage distance that our point takes between first and
 * last one in the points array
 */

export const getValueLabelOffset = (points: Array<number>, point: number)
: number => getPercentagePosition(points[0], point, points[points.length - 1]);

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../theme/colors';

export const PageContainer = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px 32px',
});

export const SideNavigator = styled(Box)({
  width: '285px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(180deg, #474A55 0%, #2B2B2B 100%)',
  boxShadow: '0px 20px 45px -10px rgba(44, 44, 44, 0.05)',
  borderRadius: '24px',
  padding: '120px 16px',
});

export const Divider = styled(Box)({
  width: '100%',
  height: '1px',
  margin: '32px 0px',
  background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51.04%, rgba(255, 255, 255, 0) 100%)',
});

export const ContentContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  paddingLeft: '32px',
});

export const ProfileActionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  alignItems: 'center',
});

export const ProfileIconContainer = styled(Box)({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '16px',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: Colors.Outline.Element,
  '&:hover': {
    backgroundColor: Colors.Main.Primary,
  },
});

export const PageTitle = styled(Typography)({
  marginTop: '24px',
  marginBottom: '48px',
});

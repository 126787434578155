import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { formatDate } from '../../../utilities/dateTimeUtils';
import { capitalizeWord } from '../../../utilities/utils';
import { HeaderTableCell, BodyTableCell, NoResults } from '../../Table';
import { UsersTableProps } from '.';
import { rolesConfig } from '../index';
import HeaderTableRow from './styles';

function UsersTable({ userRole, users, onTableRowClicked }: UsersTableProps) {
  const isHcpTable = userRole === 'hcp';
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <HeaderTableRow>
            <HeaderTableCell>Name</HeaderTableCell>
            {isHcpTable ? (
              <HeaderTableCell>Organization</HeaderTableCell>
            ) : null}
            <HeaderTableCell>Email</HeaderTableCell>
            <HeaderTableCell>Status</HeaderTableCell>
            <HeaderTableCell>Date Created</HeaderTableCell>
          </HeaderTableRow>
        </TableHead>
        <TableBody data-testid="usersTableBody">
          {users.map((user) => (
            <TableRow
              key={user.userId}
              onClick={() => onTableRowClicked(user.userId)}
            >
              <BodyTableCell>{user.name}</BodyTableCell>
              {isHcpTable ? (
                <BodyTableCell>{user.orgName}</BodyTableCell>
              ) : null}
              <BodyTableCell>{user.email}</BodyTableCell>
              <BodyTableCell>{capitalizeWord(user.status)}</BodyTableCell>
              <BodyTableCell>{formatDate(user.creationTime)}</BodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {users.length ? null : (
        <NoResults label={rolesConfig.get(userRole)?.noResultsText ?? ''} />
      )}
    </TableContainer>
  );
}

export default UsersTable;

import React from 'react';
import {
  OrganizationsPage,
  HealthCareProvidersPage,
  ManageAdminsPage,
  ConsumersPage,
  TestListPage,
} from '../pages';

export type DashboardRoute = {
  component: React.ComponentType;
  route: string;
  label: string;
  iconName: string;
};

const TestListRoute = {
  component: TestListPage,
  route: '/',
  label: 'Test List',
  iconName: 'file-copy',
};

const OrganizationRoute = {
  component: OrganizationsPage,
  route: '/organizations',
  label: 'Organizations',
  iconName: 'stream',
};

const HCPRoute = {
  component: HealthCareProvidersPage,
  route: '/hcps',
  label: 'HCPs',
  iconName: 'manage-accounts',
};

const ManageAdminsRoute = {
  component: ManageAdminsPage,
  route: '/admins',
  label: 'Manage Admins',
  iconName: 'admin-panel-settings',
};

const ConsumersRoute = {
  component: ConsumersPage,
  route: '/consumers',
  label: 'Consumers',
  iconName: 'consumers',
};

const AllDashboardRoutes = [
  TestListRoute,
  OrganizationRoute,
  HCPRoute,
  ManageAdminsRoute,
  ConsumersRoute,
];

export const getDashboardRoutesForRole = (
  role?: string,
): Array<DashboardRoute> => {
  if (role === 'hcp') {
    return [];
  }
  if (role === 'super_admin') {
    return AllDashboardRoutes;
  }
  return [TestListRoute, OrganizationRoute, HCPRoute, ConsumersRoute];
};

type routeLabelResult = string | undefined;

export const getLabelForRoute = (routeName: string): routeLabelResult => AllDashboardRoutes.find(
  (dashboardRoute) => dashboardRoute.route === routeName,
)?.label;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Container = styled(Box)({
  padding: '0px 24px',
  width: '300px',
  height: '175px',
});

export default Container;

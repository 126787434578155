export type GraphLabel = {
  label: number,
  position: number,
}

export type GraphLabelsProps = {
  labels: Array<GraphLabel>
};

export type SectionLabelProps = {
  leftOffset: number;
};

export { default as GraphLabels } from './GraphLabels';

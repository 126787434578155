import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { RadioButtonsProps } from '.';
import { Icon } from '../Icon';
import Colors from '../../theme/colors';
import { Container, ListItemText, ItemContainer } from './styles';

function RadioButtons({
  values,
  selectedValue,
  onSelect,
  width,
}: RadioButtonsProps) {
  return (
    <Container width={width} data-testid="radioButtons">
      {values.map((label) => {
        const selected = label === selectedValue;
        return (
          <ButtonBase key={label} onClick={() => onSelect(label)} disableRipple>
            <ItemContainer selected={selected}>
              <Icon
                color={selected ? Colors.Main.Light : Colors.Text.Default}
                name={selected ? 'radio-checked' : 'radio-unchecked'}
              />

              <ListItemText
                variant="BodySmallRegular"
                color={selected ? Colors.Main.Light : Colors.Text.Default}
              >
                {label}
              </ListItemText>
            </ItemContainer>
          </ButtonBase>
        );
      })}
    </Container>
  );
}

export default RadioButtons;

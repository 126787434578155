import React from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { formatDate } from '../../../utilities/dateTimeUtils';
import { capitalizeWord } from '../../../utilities/utils';
import {
  HeaderTableCell,
  BodyTableCell,
  NoResults,
} from '../../../components/Table';
import OrganizationsTableProps from '.';
import HeaderTableRow from './styles';

function OrganizationsTable({
  organizations,
  onTableRowClicked,
  onOpenOrgQuestions,
}: OrganizationsTableProps) {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <HeaderTableRow>
            <HeaderTableCell>Name</HeaderTableCell>
            <HeaderTableCell>Organization Type</HeaderTableCell>
            <HeaderTableCell>Activation Code</HeaderTableCell>
            <HeaderTableCell>Parent Organizaton</HeaderTableCell>
            <HeaderTableCell>Questions</HeaderTableCell>
            <HeaderTableCell>Status</HeaderTableCell>
            <HeaderTableCell>Date Created</HeaderTableCell>
          </HeaderTableRow>
        </TableHead>
        <TableBody data-testid="organizationsTableBody">
          {organizations.map((organization) => (
            <TableRow
              key={organization.orgId}
              onClick={() => onTableRowClicked(organization)}
            >
              <BodyTableCell>{organization.orgName}</BodyTableCell>
              <BodyTableCell>
                {organization.isConsOrganization ? 'Consumer' : 'HCP'}
              </BodyTableCell>
              <BodyTableCell>
                {organization.activationCode ?? '-'}
              </BodyTableCell>
              <BodyTableCell>
                {organization.orgRelation?.orgName ?? '-'}
              </BodyTableCell>
              <BodyTableCell
                onClick={() => {
                  onOpenOrgQuestions(organization.orgId);
                }}
              >
                link
              </BodyTableCell>
              <BodyTableCell>
                {capitalizeWord(organization.status)}
              </BodyTableCell>
              <BodyTableCell>
                {formatDate(organization.creationTime)}
              </BodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {organizations.length ? null : (
        <NoResults label="No organizations found." />
      )}
    </TableContainer>
  );
}

export default OrganizationsTable;

import React from 'react';
import { formatDate } from '../../utilities/dateTimeUtils';
import { Icon } from '../Icon';
import { DatePickerInputProps } from '.';
import { ButtonWrapper, DatePickerTextField } from './styles';
import Colors from '../../theme/colors';

function DatePickerInput({ value, onClick, id }: DatePickerInputProps) {
  const dateValue = formatDate(value) ?? 'Select Date';
  const iconVariant = value ? 'active' : 'default';
  return (
    <ButtonWrapper onClick={onClick} disableRipple>
      <DatePickerTextField
        label={id}
        variant="standard"
        disabled
        value={dateValue}
        InputProps={{
          endAdornment: <Icon name="event" variant={iconVariant} />,
          inputProps: {
            style: {
              color: value ? Colors.Text.Paragraph : Colors.Text.Default,
              WebkitTextFillColor: value
                ? Colors.Text.Paragraph
                : Colors.Text.Default,
            },
          },
        }}
      />
    </ButtonWrapper>
  );
}

export default DatePickerInput;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { HeaderTableCellProps } from '.';
import Colors from '../../../theme/colors';

function HeaderTableCell({ children }: HeaderTableCellProps) {
  return (
    <TableCell>
      <Typography color={Colors.Text.Heading} variant="BodySmallSemiBold">
        {children}
      </Typography>
    </TableCell>
  );
}

export default HeaderTableCell;

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

const Container = styled(Stack)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '64px',
  marginBottom: '64px',
});

export default Container;

import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { PageContainer, FiltersContainer } from './styles';
import {
  TableFilters as ResultFilters,
  defaultEmptyFilters as emptyFilters,
} from '../../components/Table/TableFilters';
import { TestResult, FullTestResult } from '.';
import { filterTestResults, getTestResult } from '../../api/result/resultApi';
import {
  formatFilterTestResultsResponse,
  formatFilterTestResultsParams,
  formatTestResultResponse,
} from '../../utilities/dataTransform';
import TestListTable from './TestListTable/TestListTable';
import TestResultModal from './TestResultModal/TestResultModal';
import usePagination from '../../hooks/usePagination';
import useModal from '../../hooks/useModal';

function TestListPage() {
  const {
    initialPaginationInfo,
    paginationInfo,
    setPageCount,
    onChange,
    resetPagination,
  } = usePagination();

  const { modalState, openModal, closeModal } = useModal();

  const [mounted, setMounted] = useState(false);

  const [resultFilters, setResultFilters] = useState(emptyFilters);
  const [results, setResults] = useState<Array<TestResult>>([]);
  const [selectedResult, setSelectedResult] = useState<
    FullTestResult | undefined
  >(undefined);

  const fetchResults = () => {
    const dataParams = formatFilterTestResultsParams(
      paginationInfo.page,
      50,
      resultFilters,
    );
    filterTestResults(dataParams).then((response) => {
      setPageCount(response.data.number_of_pages);
      setResults(formatFilterTestResultsResponse(response.data));
    });
  };

  useEffect(() => {
    // we ignore initial calls for this useEffect and the one with paginationInfo.page dependency
    // this will be triggered once more after initial trigger and fetchUsers() will be called
    // this is done to avoid too much unnecessary calls on mount of component
    if (mounted) {
      const pageWillChange = paginationInfo.page !== initialPaginationInfo.page;
      resetPagination();
      // if paginationInfo.page useEffect doesn't trigger fetching, do it here manually.
      if (!pageWillChange) {
        fetchResults();
      }
    } else {
      setMounted(true);
    }
  }, [resultFilters]);

  useEffect(() => {
    if (mounted) {
      fetchResults();
    }
  }, [paginationInfo.page]);

  useEffect(() => {
    if (selectedResult) {
      openModal();
    }
  }, [selectedResult]);

  const onTableRowClicked = (clickedResult: TestResult) => {
    getTestResult({
      test_id: clickedResult.testId,
      result_id: clickedResult.resultId,
    }).then((response) => {
      setSelectedResult(formatTestResultResponse(response.data));
    });
  };

  return (
    <PageContainer>
      <FiltersContainer data-testid="filtersContainer">
        <ResultFilters
          onFiltersChanged={setResultFilters}
          filterType="result"
        />
      </FiltersContainer>
      <TestListTable
        testResults={results}
        onTableRowClicked={onTableRowClicked}
      />
      {paginationInfo.pageCount > 1 ? (
        <Pagination
          data-testid="resultsPagination"
          count={paginationInfo.pageCount}
          page={paginationInfo.page}
          onChange={onChange}
          variant="outlined"
          shape="rounded"
        />
      ) : null}
      <TestResultModal
        modalState={modalState}
        onClose={closeModal}
        selectedResult={selectedResult}
      />
    </PageContainer>
  );
}

export default TestListPage;

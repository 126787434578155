import { RegisterOptions } from 'react-hook-form';
import { PASSWORD_REGEX } from '../../../utilities/consts';

export type ResetPasswordInputRules = {
  code: RegisterOptions;
  password: RegisterOptions;
  repeatPassword: RegisterOptions;
};

export type ResetPasswordFormFields = {
  code: string;
  password: string;
  repeatPassword: string;
};

export type ResetPasswordProps = {
  onNextStep: () => void;
  email: string;
};

export const generateResetPasswordInputRules = (
  getValues: (value: string) => string,
): ResetPasswordInputRules => ({
  code: { required: 'Required field' },
  password: {
    required: 'Required field',
    pattern: {
      value: PASSWORD_REGEX,
      message:
        'Password must contain a lowercase letter, an uppercase letter, a number, a special character and be at least six characters long.',
    },
  },
  repeatPassword: {
    required: 'Required field',
    validate: (value: string) => value === getValues('password') || 'The passwords do not match',
  },
});

export const defaultFieldValues: ResetPasswordFormFields = {
  code: '',
  password: '',
  repeatPassword: '',
};

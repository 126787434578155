export type ListItemPickerProps = {
  values: Array<string>;
  selectedValue?: string;
  onSelect: (item: number) => void;
  width?: number;
  onEndReached?: () => void;
  isLastPage?: boolean;
};

export { default as ListItemPicker } from './ListItemPicker';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const SearchQuery = styled(TextField)({
  marginRight: '24px',
  width: '250px',
});

const ClearFilters = styled(Typography)({
  cursor: 'pointer',
  marginLeft: '12px',
});

export { Container, SearchQuery, ClearFilters };

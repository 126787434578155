import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Colors from '../../theme/colors';

export const PageContainer = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LogoContainer = styled(Box)({
  width: '100%',
  marginTop: '64px',
  marginBottom: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FormContainer = styled(Stack)({
  width: '450px',
  padding: '32px',
});

export const InputFieldsContainer = styled(Box)({
  display: 'flex',
  height: '128px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: '48px',
  marginBottom: '48px',
});

const ForgotPasswordText = styled(Typography)({
  margin: '32px',
  textAlign: 'center',
  cursor: 'pointer',
});

ForgotPasswordText.defaultProps = {
  color: Colors.Text.Paragraph,
  variant: 'BodyMediumSemiBold',
};

const ErrorText = styled(Typography)({
  marginBottom: '16px',
  textAlign: 'center',
});

ErrorText.defaultProps = {
  variant: 'BodyMediumRegular',
  color: Colors.Status.Error,
};

export { ForgotPasswordText, ErrorText };

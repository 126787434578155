import React from 'react';

import Typography from '@mui/material/Typography';
import { Icon } from '../../../../components/Icon';
import SectionHeaderProps from '.';

import {
  Container,
  TitleContainer,
  SectionTitle,
  ToggleButtonContainer,
} from './styles';
import Colors from '../../../../theme/colors';

function SectionHeader({
  icon,
  title,
  expandable = false,
  toggleExpanded,
  expanded,
}: SectionHeaderProps) {
  return (
    <Container data-testid={title}>
      <TitleContainer>
        <Icon name={icon} size={24} color={Colors.Text.Heading} />
        <SectionTitle color={Colors.Text.Heading} variant="BodyLargeBold">
          {title}
        </SectionTitle>
      </TitleContainer>

      {expandable ? (
        <ToggleButtonContainer onClick={toggleExpanded}>
          <Typography variant="BodySmallRegular" color={Colors.Text.Link}>
            {!expanded ? 'Show' : 'Hide'}
          </Typography>
        </ToggleButtonContainer>
      ) : null}
    </Container>
  );
}

export default SectionHeader;

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { ReactComponent as Logo } from '../../images/logo.svg';
import Colors from '../../theme/colors';
import { ValidationTextField } from '../../components/ValidationTextField';
import {
  PageContainer,
  InputFieldsContainer,
  FormContainer,
  ForgotPasswordText,
  ErrorText,
  LogoContainer,
} from './styles';
import { useAuth } from '../../hooks/useAuth';
import { signInErrorMessage } from '../../utilities/errorMessageUtils';
import { LoginFormData, loginInputRules, defaultFieldValues } from '.';

function LoginPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [signInError, setSignInError] = useState<string | undefined>(undefined);
  const { handleSubmit, control } = useForm({
    defaultValues: defaultFieldValues,
  });

  useEffect(() => {
    if (auth?.sessionExpired) {
      setSignInError('Your session has expired, please sign in again.');
    }
  }, [auth?.sessionExpired]);

  useEffect(() => {
    if (auth?.isAuthorized) {
      navigate('/', { replace: true });
    }
  }, [auth]);

  useEffect(() => {
    if (signInError) {
      setSubmitDisabled(false);
    }
  }, [signInError]);

  const onSubmit = (data: LoginFormData) => {
    setSubmitDisabled(true);
    setSignInError(undefined);
    Auth.signIn(data.email, data.password)
      .then((response) => {
        const userRole = response?.signInUserSession?.idToken?.payload['custom:role'];
        if (userRole !== 'admin') {
          setSignInError('Invalid email or password');
        }
      })
      .catch((error) => {
        if (error?.message === 'User is disabled.' || error?.message === 'User is not confirmed.') {
          // User tried to login with cons/hcp account which is inactive
          setSignInError('Your account is not authorised to access this resource. If you believe this is incorrect, please contact support@mypocdoc.com');
        } else {
          setSignInError(signInErrorMessage(error.code, error.message));
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer>
        <FormContainer>
          <LogoContainer m="auto">
            <Logo width={200} height={58} />
          </LogoContainer>
          <Typography color={Colors.Text.Heading} variant="Heading3">
            Sign in to your account
          </Typography>

          <InputFieldsContainer>
            <ValidationTextField
              name="email"
              control={control}
              rules={loginInputRules.email}
              label="Email"
              autoComplete="new-password"
            />

            <ValidationTextField
              name="password"
              control={control}
              rules={loginInputRules.password}
              label="Password"
              toggleShowPassword
              autoComplete="new-password"
            />
          </InputFieldsContainer>
          {signInError ? <ErrorText>{signInError}</ErrorText> : null}
          <Button
            type="submit"
            disabled={submitDisabled}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Sign in
          </Button>
          <ForgotPasswordText onClick={() => navigate('/forgot_password')}>
            Forgot Your Password
          </ForgotPasswordText>
        </FormContainer>
      </PageContainer>
    </form>
  );
}

export default LoginPage;

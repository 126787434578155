import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ContentContainer = styled(Box)({
  padding: '32px',
  width: '460px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
});

export default ContentContainer;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { BodyTableCellProps } from '.';
import Colors from '../../../theme/colors';

function BodyTableCell({ children, onClick }: BodyTableCellProps) {
  return (
    <TableCell
      onClick={(event) => {
        if (onClick) {
          event.stopPropagation();
          onClick();
        }
      }}
    >
      <Typography
        color={onClick ? Colors.Text.Link : Colors.Text.Paragraph}
        variant="BodySmallRegular"
      >
        {children}
      </Typography>
    </TableCell>
  );
}

export default BodyTableCell;

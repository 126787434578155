import { ReactComponent as AdminPanelSettings } from './admin_panel_settings.svg';
import { ReactComponent as ArrowBack } from './arrow_back.svg';
import { ReactComponent as Meter } from './meter.svg';
import { ReactComponent as ArrowForward } from './arrow_forward.svg';
import { ReactComponent as BloodType } from './bloodtype.svg';
import { ReactComponent as Biomarkers } from './biomarkers.svg';
import { ReactComponent as CheckCircle } from './check_circle.svg';
import { ReactComponent as CheckCircleOutline } from './check_circle_outline.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Consumers } from './consumers.svg';
import { ReactComponent as Done } from './done.svg';
import { ReactComponent as Event } from './event.svg';
import { ReactComponent as Face } from './face.svg';
import { ReactComponent as FileCopy } from './file_copy.svg';
import { ReactComponent as HealthInfo } from './health_info.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as KeyboardArrowDown } from './keyboard_arrow_down.svg';
import { ReactComponent as KeyboardArrowLeft } from './keyboard_arrow_left.svg';
import { ReactComponent as KeyboardArrowRight } from './keyboard_arrow_right.svg';
import { ReactComponent as KeyboardArrowUp } from './keyboard_arrow_up.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as ManageAccounts } from './manage_accounts.svg';
import { ReactComponent as MoreVert } from './more_vert.svg';
import { ReactComponent as RadioChecked } from './radio-checked.svg';
import { ReactComponent as RadioUnchecked } from './radio-unchecked.svg';
import { ReactComponent as Report } from './report.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as SpaceDashboard } from './space_dashboard.svg';
import { ReactComponent as Stream } from './stream.svg';
import { ReactComponent as VisibilityOff } from './visibility_off.svg';
import { ReactComponent as Visibility } from './visibility.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Photo } from './photo.svg';
import { ReactComponent as Results } from './results.svg';
import { ReactComponent as QuestionAnswer } from './question_answer.svg';
import { ReactComponent as Qrisk } from './risk.svg';
import { ReactComponent as Accessibility } from './accessibility.svg';
import { ReactComponent as Biotech } from './biotech.svg';
import { ReactComponent as Envelope } from './envelope-icon.svg';

export {
  AdminPanelSettings,
  Biomarkers,
  Photo,
  Accessibility,
  Biotech,
  ArrowBack,
  Meter,
  ArrowForward,
  BloodType,
  CheckCircle,
  CheckCircleOutline,
  QuestionAnswer,
  Checked,
  Close,
  Consumers,
  Done,
  Event,
  Face,
  FileCopy,
  HealthInfo,
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Logout,
  ManageAccounts,
  MoreVert,
  RadioChecked,
  RadioUnchecked,
  Report,
  Results,
  Search,
  SpaceDashboard,
  Stream,
  VisibilityOff,
  Visibility,
  Qrisk,
  Warning,
  Envelope,
};

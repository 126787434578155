import React from 'react';
import {
  Container, SectionLabel,
} from './styles';
import { GraphLabelsProps } from '.';

function GraphLabels({
  labels,
}: GraphLabelsProps) {
  return (
    <Container>
      {labels.map(({ position, label }) => (
        <SectionLabel key={position} leftOffset={position}>
          {label.toFixed(1)}
        </SectionLabel>
      ))}
    </Container>
  );
}

export default GraphLabels;

import React from 'react';
import Colors from '../../../../../theme/colors';
import { Container, Timestamp } from './styles';
import { ConditionalLink } from '../../../../../components/ConditionalLink';
import { formatDateToLongFormat } from '../../../../../utilities/dateTimeUtils';

type ErrorLogProps = {
  errorType: string;
  timeStamp: string;
  imageLink: string;
};

export default function ErrorLog({ errorType, timeStamp, imageLink }: ErrorLogProps) {
  return (
    <Container>
      <ConditionalLink successText={errorType} url={imageLink} variant="BodySmallSemiBold" />
      <Timestamp variant="BodySmallSemiBold" color={Colors.Text.Heading}>
        {formatDateToLongFormat(timeStamp)}
      </Timestamp>
    </Container>
  );
}

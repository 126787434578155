import { RegisterOptions } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../utilities/consts';

export type ForgotPasswordInputRules = {
    email: RegisterOptions;
  }
export type ForgotPasswordFormFields = {
    email: string;
  };

export type ForgotPasswordProps = {
    onConfirmationCodeSent: (email: string) => void;
  }

export const forgotPasswordInputRules: ForgotPasswordInputRules = {
  email: {
    required: 'Required field',
    pattern: { value: EMAIL_REGEX, message: 'Invalid email format' },
  },
};

export const defaultFieldValues: ForgotPasswordFormFields = {
  email: '',
};

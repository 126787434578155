export type RadioButtonsProps = {
  values: Array<string>;
  selectedValue: string;
  onSelect: (item: string) => void;
  width?: number;
};

export type ItemContainerProps = {
  selected: boolean;
};

export { default as RadioButtons } from './RadioButtons';

export enum RiskFactor {
  Neutral = 1,
  Caution = 2,
}

export type TestResult = {
  testId: string;
  resultId: string;
  organization: string;
  testType: string;
  hcpName: string;
  hcpEmail: string;
  deviceId: string;
  lfdLot: string | null;
  status: string;
  dateCreated: string;
};

export type HealthInformationAnswer = {
  label: string;
  value?: string;
  riskFactor: RiskFactor;
  influencesBiomarkers?: boolean;
};

export type Answer = {
  label: string;
  value?: string;
  questionTitle?: string;
};

export type FeedbackSection = { sectionLabel: string; answers: Array<Answer> };

export type FeedbackAnswer = Answer | FeedbackSection;

export function isFeedbackElementSection(obj: FeedbackAnswer): obj is FeedbackSection {
  return 'sectionLabel' in obj;
}

export function isFeedbackElementAnswer(obj: FeedbackAnswer): obj is Answer {
  return 'label' in obj;
}

export type SubjectData = {
  testId: string;
  createdAt: string;
  testStartedAt: string;
  subjectEmail?: string;
  newsAndAnnouncements: boolean;
  bmi: number;
  answers: Array<HealthInformationAnswer>;
  personalAnswers: Array<Answer>;
  feedbackAnswers: Array<FeedbackAnswer>;
  nhsNumber: string;
  odsCode: string;
};

export type AdditionalResultData = {
  triglycerides: string;
  hdl: string;
  totalCholesterol: string;
  nonHdl: string;
  tcHdlRatio: string;
};

type Image = {
  order: number;
  s3_url: string;
}

export type Result = {
  status: string;
  totalCholesterol: { value?: number; thresholdExceeded: boolean };
  hdl: { value?: number; thresholdExceeded: boolean };
  nonHdl: { value?: number; thresholdExceeded: boolean };
  ldl: { value?: number; thresholdExceeded: boolean };
  totalHdlRatio: { value?: number; thresholdExceeded: boolean };
  triglycerides: { value?: number; thresholdExceeded: boolean };
  resultData: {
    l: AdditionalResultData;
    a: AdditionalResultData;
    b: AdditionalResultData;
    rawValues: AdditionalResultData;
    publishedValues: AdditionalResultData;
  };
  pdfUrl: string;
  pdfPass: string;
  pdfName: string;
  warningMessage: string | null;
  images: {
    barcodesAndRois?: Image;
    colorCorrected?: Image;
    final?: Image;
    inputCropped?: Image;
    shadeCorrected?: Image;
    whiteBalanced?: Image;
    linearized?: Image;
    original?: Image;
    rois?: Image;
    sitesBinaryMask?: Image;
    levelsAdjusted?: Image;
  };
  metadata: Array<Answer>;
  qrisk: {
    score10Year: string | null;
    publishedScore10Year: string | null;
    scoreHealthy: string | null;
    publishedScoreHealthy: string | null;
    heartAge: string | null;
    publishedHeartAge: string | null;
    status: string;
  };
};

export type ErrorLog = {
  errorId: string;
  errorType: string;
  createdAt: string;
  imgCapturedAt: string;
  timerStartedAt: string;
  testSubmittedAt: string;
  images: {
    original: {
      order: number;
      s3_url: string;
    };
  };
};

export type NHSEmailMeta = {
  pdfName: string;
  pdfUrl: string;
  sentAt: string;
  status: string;
}

export type PKBMessageMeta = {
  status: string;
  sentAt: string | null;
}

export type FullTestResult = {
  subjectData: SubjectData;
  results: Result;
  errorLogs: ErrorLog[];
  nhsEmailMeta?: NHSEmailMeta;
  pkbMessageMeta?: PKBMessageMeta;
};

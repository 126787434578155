import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: Colors.TestResultsRowBackground,
  marginTop: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const Timestamp = styled(Typography)({
  marginLeft: 'auto',
  marginRight: '49%',
});

export { Container, Timestamp };

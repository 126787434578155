import { useState } from 'react';

/**
 * ADD/EDIT are used for action modal types, while OPENED is used for data display modals
 */
export enum ModalStates {
    ADD = 'ADD',
    EDIT = 'EDIT',
    CLOSED = 'CLOSED',
    OPENED = 'OPENED',
  }

const useModal = () => {
  const [modalState, setModalState] = useState<ModalStates>(ModalStates.CLOSED);

  const openAddModal = () => {
    setModalState(ModalStates.ADD);
  };

  const openEditModal = () => {
    setModalState(ModalStates.EDIT);
  };

  const openModal = () => {
    setModalState(ModalStates.OPENED);
  };

  const closeModal = () => {
    setModalState(ModalStates.CLOSED);
  };

  return {
    modalState, openAddModal, openEditModal, openModal, closeModal,
  };
};

export default useModal;

import React from 'react';
import { Container, Section } from './styles';
import { ColorBarProps } from '.';

/**
 * Displays numeric ranges as a sectioned colored bar.
 * @param {ColorBarProps} props contains percentages that each section should take,
 * as well as colors to represent each section with.
 * @returns for sections=[30,20,50] and colors=['red','blue','yellow']
 * we would receive 3 blocks in 30%/20%/50% proportion, colored red blue and yellow.
 */
function ColorBar({ sections, colors }: ColorBarProps) {
  return (
    <Container>
      {sections.map((section, i) => (
        <Section
          key={i}
          sectionPercentage={section}
          color={colors[i]}
        />
      ))}
    </Container>
  );
}
export default ColorBar;

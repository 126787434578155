import React, { useState } from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import AnsweredQuestionsProps from '.';
import AnsweredQuestion from './AnsweredQuestion/AnsweredQuestion';

import Icon from '../../../../components/Icon/Icon';
import { RiskFactor } from '../../index';
import Colors from '../../../../theme/colors';
import {
  Container, ContentContainer, LegendContainer, LegendLabel, LegendDescription, LegendRow,
} from './styles';

function AnsweredQuestions({ answers }: AnsweredQuestionsProps) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  const hasInfluencesBiomarkers = answers.some((answer) => answer.influencesBiomarkers);
  const hasRiskFactors = answers.some((answer) => answer.riskFactor === RiskFactor.Caution);
  const shouldShowLegend = hasInfluencesBiomarkers || hasRiskFactors;

  return (
    <Container>
      <SectionHeader
        icon="health-info"
        title="Health Information"
        expandable
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ContentContainer>
          {shouldShowLegend ? (
            <LegendContainer>
              <LegendLabel>Legend</LegendLabel>
              {hasInfluencesBiomarkers ? (
                <LegendRow>
                  <Icon name="biomarkers" variant="default" />
                  <LegendDescription>
                    Answers that could potentially influence your blood biomarker levels
                  </LegendDescription>
                </LegendRow>
              ) : null}
              {hasRiskFactors ? (
                <LegendRow>
                  <Icon name="warning" color={Colors.Status.Warning} />
                  <LegendDescription>
                    Caution - Answers that may affect your cardiovascular disease risk
                  </LegendDescription>
                </LegendRow>
              ) : null}
            </LegendContainer>
          ) : null}
          {answers.map(({
            label, value, riskFactor, influencesBiomarkers,
          }) => (
            <AnsweredQuestion
              key={label}
              question={label}
              answer={value ?? 'N/A'}
              riskFactor={riskFactor}
              influencesBiomarkers={!!influencesBiomarkers}
            />
          ))}
        </ContentContainer>
      ) : null}
    </Container>
  );
}

export default AnsweredQuestions;

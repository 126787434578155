import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Colors from '../../theme/colors';
import { FilterButtonProps } from '.';

const FilterButton = styled(Box)<BoxProps & FilterButtonProps>((props) => ({
  minWidth: '166px',
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 16px',
  border: '1px solid',
  borderRadius: '10px',
  marginRight: '12px',
  borderColor: props.filteractive ? Colors.Outline.Blue : Colors.Outline.Element,
  cursor: 'pointer',
}));

const FilterButtonText = styled(Typography)<TypographyProps & FilterButtonProps>((props) => ({
  color: props.filteractive ? Colors.Text.Link : Colors.Text.Default,
  paddingRight: '8px',
}));

export { FilterButton, FilterButtonText };

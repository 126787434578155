import React from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { formatDateToLongFormat } from '../../../utilities/dateTimeUtils';
import {
  HeaderTableCell,
  NoResults,
  BodyTableCell,
  ResultStatusTableCell,
} from '../../../components/Table';
import TestListTableProps from '.';
import HeaderTableRow from './styles';

function TestListTable({ testResults, onTableRowClicked }: TestListTableProps) {
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <HeaderTableRow>
            <HeaderTableCell>Test ID</HeaderTableCell>
            <HeaderTableCell>Organization</HeaderTableCell>
            <HeaderTableCell>Test Type</HeaderTableCell>
            <HeaderTableCell>Name</HeaderTableCell>
            <HeaderTableCell>Email</HeaderTableCell>
            <HeaderTableCell>Device ID</HeaderTableCell>
            <HeaderTableCell>Lot Number</HeaderTableCell>
            <HeaderTableCell>Status</HeaderTableCell>
            <HeaderTableCell>Date Created</HeaderTableCell>
          </HeaderTableRow>
        </TableHead>

        <TableBody data-testid="resultsTableBody">
          {testResults.map((result) => (
            <TableRow
              key={result.resultId}
              onClick={() => onTableRowClicked(result)}
            >
              <BodyTableCell>{result.testId}</BodyTableCell>
              <BodyTableCell>{result.organization}</BodyTableCell>
              <BodyTableCell>{result.testType === 'self-test' ? 'Self-Test' : 'Pro'}</BodyTableCell>
              <BodyTableCell>{result.hcpName}</BodyTableCell>
              <BodyTableCell>{result.hcpEmail}</BodyTableCell>
              <BodyTableCell>{result.deviceId}</BodyTableCell>
              <BodyTableCell>{result.lfdLot ?? 'N/A'}</BodyTableCell>
              <ResultStatusTableCell status={result.status} />
              <BodyTableCell>{formatDateToLongFormat(result.dateCreated)}</BodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {testResults.length ? null : <NoResults label="No tests found." />}
    </TableContainer>
  );
}

export default TestListTable;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  forgotPasswordInputRules,
  ForgotPasswordProps,
  ForgotPasswordFormFields,
  defaultFieldValues,
} from '.';
import { ValidationTextField } from '../../../components/ValidationTextField';
import Colors from '../../../theme/colors';
import {
  ReturnToSignInText,
  FormContainer,
  InputFieldsContainer,
  ErrorText,
} from './styles';
import { forgotPasswordErrorMessage } from '../../../utilities/errorMessageUtils';

function ForgotPassword({ onConfirmationCodeSent }: ForgotPasswordProps) {
  const [forgotPasswordError, setForgotPasswordError] = useState<string | null>(
    null,
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    defaultValues: defaultFieldValues,
  });

  const onSubmit = ({ email }: ForgotPasswordFormFields) => {
    setForgotPasswordError(null);
    setSubmitDisabled(true);
    Auth.forgotPassword(email)
      .then(() => {
        setSubmitDisabled(false);
        onConfirmationCodeSent(email);
      })
      .catch((error: any) => {
        setSubmitDisabled(false);
        setForgotPasswordError(forgotPasswordErrorMessage(error.code));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <InputFieldsContainer>
          <ValidationTextField
            name="email"
            control={control}
            rules={forgotPasswordInputRules.email}
            label="Email"
          />
        </InputFieldsContainer>
        {forgotPasswordError ? (
          <ErrorText color={Colors.Status.Error} variant="BodyMediumRegular">
            {forgotPasswordError}
          </ErrorText>
        ) : null}
        <Button
          type="submit"
          disabled={submitDisabled}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Reset my password
        </Button>
        <ReturnToSignInText
          color={Colors.Text.Paragraph}
          variant="BodyMediumSemiBold"
          onClick={() => navigate('/login')}
        >
          Return to sign in
        </ReturnToSignInText>
      </FormContainer>
    </form>
  );
}

export default ForgotPassword;

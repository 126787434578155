import React, { useEffect } from 'react';
import { ModalDropdownInput } from '../Modal';
import { OrganizationPickerProps } from '.';

import { filterOrganizations } from '../../api/organization/organization';
import usePagination from '../../hooks/usePagination';
import { formatGetOrganizationsResponse } from '../../utilities/dataTransform';

function OrganizationPicker({
  showPicker,
  name,
  rules,
  control,
  label,
  orgStatus,
  organizations,
  selectedOrg,
  setOrganizations,
  setSelectedOrg,
}: OrganizationPickerProps) {
  const {
    paginationInfo, setPageCount, setPage, resetPagination,
  } = usePagination();

  const getOrganizations = () => {
    filterOrganizations({
      page_number: paginationInfo.page,
      page_size: 20,
      org_type: 'hcp',
      status: orgStatus,
    }).then(({ data }) => {
      setPageCount(data.number_of_pages);
      const formattedOrganizations = formatGetOrganizationsResponse(data);
      setOrganizations(formattedOrganizations);
    });
  };

  useEffect(() => {
    if (showPicker) {
      getOrganizations();
    } else {
      resetPagination();
    }
  }, [showPicker]);

  const getNextOrganizationsPage = () => {
    filterOrganizations({
      page_number: paginationInfo.page,
      page_size: 20,
      org_type: 'hcp',
      status: orgStatus,
    }).then(({ data }) => {
      setPageCount(data.number_of_pages);
      const formattedOrganizations = formatGetOrganizationsResponse(data);
      setOrganizations(formattedOrganizations);
    });
  };

  useEffect(() => {
    // This is for infinite scroll pagination,
    // it is why we are allowed to do this, for page pagination it wouldn't work
    if (paginationInfo.page > 1) {
      getNextOrganizationsPage();
    }
  }, [paginationInfo.page]);

  const selectOrganization = (itemIndex: number) => {
    setSelectedOrg(organizations[itemIndex]);
  };

  const onNextPage = () => {
    setPage(paginationInfo.page + 1);
  };

  return showPicker ? (
    <ModalDropdownInput
      name={name}
      rules={rules}
      control={control}
      label={label}
      dropdownItems={organizations.map((org) => org.orgName)}
      isLastPage={paginationInfo.page === paginationInfo.pageCount}
      onEndReached={onNextPage}
      selectedItem={selectedOrg?.orgName}
      onSelect={selectOrganization}
    />
  ) : null;
}

export default OrganizationPicker;

/* eslint-disable no-unused-vars */
import React from 'react';
import { createTheme } from '@mui/material/styles';
import Colors from './colors';
import FontStyles from './fonts';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        Heading1: React.CSSProperties;
        Heading2: React.CSSProperties;
        Heading3: React.CSSProperties;
        Heading4: React.CSSProperties;
        BodyLargeRegular: React.CSSProperties;
        BodyLargeSemiBold: React.CSSProperties;
        BodyLargeBold: React.CSSProperties;
        BodyMediumBold: React.CSSProperties;
        BodyMediumSemiBold: React.CSSProperties;
        BodyMediumRegular: React.CSSProperties;
        BodySmallSemiBold: React.CSSProperties;
        BodySmallRegular: React.CSSProperties;
        BodySmallBoldPoppins: React.CSSProperties;
        BodySmallBoldOpen: React.CSSProperties;
        BodyXSmallRegular: React.CSSProperties;
        BodyXSmallExtraBold: React.CSSProperties;
      }

      // allow configuration using `createTheme`
      interface TypographyVariantsOptions {
        Heading1?: React.CSSProperties;
        Heading2: React.CSSProperties;
        Heading3: React.CSSProperties;
        Heading4: React.CSSProperties;
        BodyLargeRegular: React.CSSProperties;
        BodyLargeSemiBold: React.CSSProperties;
        BodyLargeBold: React.CSSProperties;
        BodyMediumBold: React.CSSProperties;
        BodyMediumSemiBold: React.CSSProperties;
        BodyMediumRegular: React.CSSProperties;
        BodySmallSemiBold: React.CSSProperties;
        BodySmallRegular: React.CSSProperties;
        BodySmallBoldPoppins: React.CSSProperties;
        BodySmallBoldOpen: React.CSSProperties;
        BodyXSmallRegular: React.CSSProperties;
        BodyXSmallExtraBold: React.CSSProperties;
      }

}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        Heading1: true;
        Heading2: true;
        Heading3: true;
        Heading4: true;
        BodyLargeRegular: true;
        BodyLargeSemiBold: true;
        BodyLargeBold: true;
        BodyMediumBold: true;
        BodyMediumSemiBold: true;
        BodyMediumRegular: true;
        BodySmallSemiBold: true;
        BodySmallBoldPoppins: true;
        BodySmallRegular: true;
        BodySmallBoldOpen: true;
        BodyXSmallRegular: true;
        BodyXSmallExtraBold: true;
    }
  }

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          width: '4px',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#9F9F9F',
          borderRadius: '4px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.Outline.Element,
          '&.Mui-checked': {
            color: Colors.Main.Primary,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: Colors.Outline.Element,
            cursor: 'pointer',
          },
          '&:last-child td, &:last-child th': { border: 0 },
        },
        head: {
          ':hover': {
            backgroundColor: Colors.Main.Light,
            cursor: 'auto',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: '650px',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          alignSelf: 'flex-end',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginTop: '48px',
          border: '1px solid',
          borderColor: Colors.Outline.Element,
          borderRadius: '8px',
          marginBottom: '24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...FontStyles.Body.Medium.SemiBold,
          backgroundColor: Colors.Action.Default,
          paddingTop: '16px',
          paddingBottom: '16px',
          borderRadius: '10px',
          textTransform: 'none',
          boxShadow: 'none',
          color: Colors.Main.Light,
          '&.Mui-disabled': {
            backgroundColor: Colors.Action.Disabled,
          },
          '&:hover': {
            backgroundColor: Colors.Action.Active,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: Colors.Action.Default,
            borderWidth: '1px',
            backgroundColor: Colors.Main.Light,
            color: Colors.Text.Link,
            '&:hover': {
              backgroundColor: Colors.Action.Active,
              color: Colors.Main.Light,
            },
            '&.Mui-disabled': {
              borderColor: Colors.Action.Disabled,
              borderWidth: '1px',
              backgroundColor: Colors.Main.Light,
              color: Colors.Action.Disabled,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: Colors.Icon.Disabled,
          opacity: '1',
        },
        thumb: {
          backgroundColor: Colors.Main.Light,
          border: '1px solid',
          borderColor: Colors.Outline.Element,
        },
        switchBase: {
          '&.Mui-checked': {
            '+.MuiSwitch-track': {
              backgroundColor: Colors.Icon.Active,
              opacity: '1',
            },
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: { horizontal: 0, vertical: -10 },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          caretColor: Colors.Main.Primary,
          ':before': {
            borderBottom: `1px solid ${Colors.Outline.Element}`,
          },
          ':hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${Colors.Outline.Element}`,
          },
          ':after': {
            borderBottom: `1px solid ${Colors.Outline.Element}`,
          },
          '&.Mui-error': {
            '&.MuiFormHelperText': {
              color: Colors.Status.Pending,
            },
            ':after': {
              borderBottom: `2px solid ${Colors.Status.Error}`,
            },
          },
        },
        input: {
          color: Colors.Text.Paragraph,
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: Colors.Status.Error,

          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...FontStyles.Body.Medium.Regular,
          color: Colors.Text.Default,
          '&.Mui-focused': {
            color: Colors.Text.Default,
          },
          '&.Mui-error': {
            color: Colors.Status.Error,
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    Heading1: FontStyles.Heading.Heading1,
    Heading2: FontStyles.Heading.Heading2,
    Heading3: FontStyles.Heading.Heading3,
    Heading4: FontStyles.Heading.Heading4,
    BodyLargeRegular: FontStyles.Body.Large.Regular,
    BodyLargeSemiBold: FontStyles.Body.Large.SemiBold,
    BodyLargeBold: FontStyles.Body.Large.Bold,
    BodyMediumBold: FontStyles.Body.Medium.Bold,
    BodyMediumSemiBold: FontStyles.Body.Medium.SemiBold,
    BodyMediumRegular: FontStyles.Body.Medium.Regular,
    BodySmallSemiBold: FontStyles.Body.Small.SemiBold,
    BodySmallRegular: FontStyles.Body.Small.Regular,
    BodySmallBoldPoppins: FontStyles.Body.Small.BoldPoppins,
    BodySmallBoldOpen: FontStyles.Body.Small.BoldOpen,
    BodyXSmallRegular: FontStyles.Body.XSmall.Regular,
    BodyXSmallExtraBold: FontStyles.Body.XSmall.ExtraBold,
  },
});

export default theme;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Container = styled(Box)({
  padding: '32px',
  width: '378px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled(Typography)({
  marginBottom: '16px',
});

const ActionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: '48px',
  justifyContent: 'space-between',
});

const ActionButton = styled(Button)({ width: '151px' });

export {
  Container,
  Header,
  ActionsContainer,
  ActionButton,
};

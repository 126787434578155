import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { CircleProps } from '~/components/Table/ResultStatusTableCell';
import Typography from '@mui/material/Typography';
import Colors from '../../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.Main.Light,
  borderRadius: '10px',
  overflow: 'hidden',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const Circle = styled(Box)<BoxProps & CircleProps>((props) => ({
  height: '12px',
  width: '12px',
  borderRadius: '6px',
  marginRight: '12px',
  backgroundColor: props.color,
}));

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: Colors.TestResultsRowBackground,
  marginTop: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const Label = styled(Typography)({
  display: 'flex',
  flex: 39,
});

const Value = styled(Typography)({
  display: 'flex',
  flex: 61,
  justifyContent: 'start',
  alignItems: 'center',
});

const LabelWithStatus = styled(Typography)({
  display: 'flex',
  flex: 36,
});

const ValueWithStatus = styled(Typography)({
  display: 'flex',
  flex: 64,
  justifyContent: 'start',
  alignItems: 'center',
});

const Text = styled(Typography)({});

export {
  Container,
  ContentContainer,
  Row,
  Label,
  Value,
  LabelWithStatus,
  ValueWithStatus,
  Text,
};

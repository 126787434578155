import React, { useState } from 'react';
import Colors from '../../../../theme/colors';
import {
  formatPKBStatus,
  getEmailStatusColor,
  getPkbStatusColor,
  capitalizeWord,
  formatStatus,
} from '../../../../utilities/utils';
import { formatDateToLongFormat } from '../../../../utilities/dateTimeUtils';
import SectionHeader from '../SectionHeader/SectionHeader';
import {
  Circle, Container, ContentContainer, Label, Value, Row, Text,
  LabelWithStatus,
  ValueWithStatus,
} from './styles';
import EHRIntegrationsProps from '.';

function EHRIntegrations({ nhsEmailMeta, pkbMessageMeta }: EHRIntegrationsProps) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((value) => !value);
  };

  return (
    <Container>
      <SectionHeader
        icon="envelope"
        title={`EHR Integration ${nhsEmailMeta || pkbMessageMeta ? '' : ' (Unavailable)'}`}
        expandable={!!(nhsEmailMeta || pkbMessageMeta)}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
      {expanded ? (
        <ContentContainer>
          <Row>
            <LabelWithStatus variant="BodyMediumBold" color={Colors.Text.Paragraph}>Email status</LabelWithStatus>
            <ValueWithStatus variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
              <Circle color={getEmailStatusColor(nhsEmailMeta?.status)} />
              {nhsEmailMeta && capitalizeWord(formatStatus(nhsEmailMeta?.status))}
            </ValueWithStatus>
          </Row>
          <Row>
            <Label variant="BodyMediumRegular" color={Colors.Text.Paragraph}>Sent</Label>
            <Value variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
              {formatDateToLongFormat(nhsEmailMeta?.sentAt)}
            </Value>
          </Row>
          {pkbMessageMeta && (
            <>
              <Row>
                <LabelWithStatus variant="BodyMediumBold" color={Colors.Text.Paragraph}>PKB status</LabelWithStatus>
                <ValueWithStatus variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
                  <Circle color={getPkbStatusColor(pkbMessageMeta?.status)} />
                  {capitalizeWord(formatPKBStatus(pkbMessageMeta?.status))}
                </ValueWithStatus>
              </Row>
              <Row>
                <Label variant="BodyMediumBold" color={Colors.Text.Paragraph}>Sent</Label>
                <Value variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
                  {pkbMessageMeta?.sentAt ? formatDateToLongFormat(pkbMessageMeta?.sentAt) : 'N/A'}
                </Value>
              </Row>
            </>
          )}
        </ContentContainer>
      ) : null}
    </Container>
  );
}

export default EHRIntegrations;

import { AxiosPromise } from 'axios';
import axios from '../../utilities/axios';
import {
  FilterTestResultsParams,
  FilterTestResultsResponse,
  GetTestResultParams,
  GetTestResultResponse,
} from '.';

export const RESULT_ROUTES = {
  FILTER: '/results/filter',
  GET_RESULT: '/results/',
};

function joinWithUnderscore(strings? : Array<string>) {
  return strings?.map((string) => string.split(' ').join('_'));
}

export function filterTestResults(data: FilterTestResultsParams):
 AxiosPromise<FilterTestResultsResponse> {
  const formattedStatuses = joinWithUnderscore(data.statuses);
  const correctData = {
    ...data,
    statuses: formattedStatuses,
  };
  return axios.getInstance().post(RESULT_ROUTES.FILTER, correctData);
}

export function getTestResult(data: GetTestResultParams):
AxiosPromise<GetTestResultResponse> {
  return axios.getInstance().get(`${RESULT_ROUTES.GET_RESULT}?test_id=${data.test_id}&result_id=${data.result_id}`);
}

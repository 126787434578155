import { AxiosPromise } from 'axios';
import axios from '../../utilities/axios';
import {
  CreateUserParams,
  GetUserResponse,
  UpdateUserParams,
  GetUsersResponse,
  FilterUsersParams,
  GetConsumersParams,
  GetConsumersResponse,
} from '.';

export const USER_ROUTES = {
  CREATE: '/user/',
  UPDATE: '/user/',
  FILTER: '/user/filter',
  GET_USER: '/user/',
  RESEND_EMAIL: '/user/resend-email',
  CHECK_AVAILABILITY: '/user/user-by-email',
  GET_CONSUMERS: '/user/consumers',
};

export function createUser(
  data: CreateUserParams,
): AxiosPromise<GetUserResponse> {
  return axios.getInstance().post(USER_ROUTES.CREATE, data);
}

export function updateUser(
  data: UpdateUserParams,
): AxiosPromise<GetUserResponse> {
  return axios.getInstance().patch(USER_ROUTES.UPDATE, data);
}

export function filterUsers(
  data: FilterUsersParams,
): AxiosPromise<GetUsersResponse> {
  return axios.getInstance().post(USER_ROUTES.FILTER, data);
}

export function resendInvitation(userId: string): AxiosPromise {
  return axios
    .getInstance()
    .post(`${USER_ROUTES.RESEND_EMAIL}?user_id=${userId}`);
}

export function getUser(userId: string): AxiosPromise<GetUserResponse> {
  return axios.getInstance().get(`${USER_ROUTES.GET_USER}?user_id=${userId}`);
}

export function getEmailAvailability(email: string) {
  const encodedEmail = encodeURIComponent(email);
  return axios
    .getInstance()
    .get(`${USER_ROUTES.CHECK_AVAILABILITY}?user_email=${encodedEmail}`);
}

export function getConsumers(
  data: GetConsumersParams,
): AxiosPromise<GetConsumersResponse> {
  return axios
    .getInstance()
    .post(USER_ROUTES.GET_CONSUMERS, data);
}

import { DateRange } from '../../../utilities/dateTimeUtils';

export type TableFiltersData = {
  searchQuery: string;
  dateCreated: DateRange;
  dateLastUsed?: DateRange;
  status: string;
};

export type FilterType =
  | 'result'
  | 'admin'
  | 'hcp'
  | 'organization'
  | 'consumer';

export type TableFiltersProps = {
  onFiltersChanged: (filters: TableFiltersData) => void;
  filterType: FilterType;
};

export const defaultEmptyFilters: TableFiltersData = {
  searchQuery: '',
  dateCreated: {
    start: '',
    end: '',
  },
  status: '',
};

export const consumerEmptyFilters: TableFiltersData = {
  ...defaultEmptyFilters,
  dateLastUsed: {
    start: '',
    end: '',
  },
};

export const activeStatusOptions = ['Active', 'Inactive'];
export const resultStatusOptions = ['Pending', 'Successful', 'reactions_failed', 'time_exceeded'];

export { default as TableFilters } from './TableFilters';

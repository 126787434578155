/* eslint-disable no-underscore-dangle */
import axiosImport, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { User } from '../hooks/useAuth';

class axios {
  static instance?: AxiosInstance = undefined;

  static user: User = {};

  static token?: string = undefined;

  static updateToken?: () => Promise<boolean> = undefined;

  static setUpdateTokenMethod(method: () => Promise<boolean>) {
    this.updateToken = method;
  }

  static setToken = (accessToken: string | undefined) => {
    this.token = accessToken;
  };

  static signOutUser?: () => void = undefined;

  static setSignOutUserMethod(method: () => Promise<boolean>) {
    this.signOutUser = method;
  }

  static getInstance() {
    if (axios.instance == null) {
      axios.instance = axiosImport.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      axios.instance.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          if (axios?.token && config?.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = axios.token;
          }
          return config;
        },
        (error) => {
          Promise.reject(error);
        },
      );
      axios.instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          const errorMessage = error?.response?.data?.detail || '';
          const statusCode = error.response.status;
          if (
            axios.signOutUser
            && ((statusCode === 401 && errorMessage === 'User disabled.')
              || (statusCode === 403 && errorMessage === 'User deleted.'))
          ) {
            await axios.signOutUser();
            return Promise.reject(error.response);
          }
          if (
            axios.updateToken
            && axios?.token
            && statusCode === 401
            && errorMessage === 'Error validating access token: Session has expired'
          ) {
            const tokenRefreshed = await axios.updateToken();
            if (tokenRefreshed && axios.instance) {
              originalRequest._retry = true;
              return axios.instance(originalRequest);
            }
            return Promise.reject(error.response);
          }
          // TODO handle other error types
          return Promise.reject(error.response);
        },
      );
    }
    return axios.instance;
  }
}

export default axios;

import { Control, RegisterOptions } from 'react-hook-form';
import { Organization } from '../../pages/OrganizationsPage';
import { UserOrganization } from '../UserPage';

export type OrganizationPickerProps = {
  showPicker: boolean;
  name: string;
  rules?: RegisterOptions;
  control: Control<any, any>;
  label: string;
  orgStatus?: string;
  organizations: Array<Organization>;
  selectedOrg: UserOrganization | undefined;
  setOrganizations: (organizations: Array<Organization>) => void;
  setSelectedOrg: (organization: UserOrganization | undefined) => void;
};

export { default as OrganizationPicker } from './OrganizationPicker';

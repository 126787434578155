import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../theme/colors';

const Container = styled(Box)({
  minWidth: '200px',
  maxHeight: '225px',
  borderRadius: '10px',
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  alignItems: 'start',
});

const ItemContainer = styled(Box)({
  maxHeight: '40px',
  maxWidth: '160px',
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '8px',
  paddingBottom: '8px',
});

const ListItemText = styled(Typography)({
  width: '100%',
  textAlign: 'left',
  alignSelf: 'center',
  '&:hover': {
    color: Colors.Text.Link,
  },
});

export { Container, ItemContainer, ListItemText };

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import Colors from '../../theme/colors';

const Container = styled(Box)({
  minWidth: '200px',
  maxHeight: '225px',
  borderRadius: '10px',
  padding: '24px 24px 0px 24px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

const ListItemText = styled(Typography)({
  marginBottom: '24px',
  width: '100%',
  textAlign: 'left',
  '&:hover': {
    color: Colors.Text.Link,
  },
});

const InfiniteScrollWrapper = styled(InfiniteScroll)({
  display: 'flex',
  flexDirection: 'column',
});

export { Container, ListItemText, InfiniteScrollWrapper };

import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Colors from '../../../../../theme/colors';
import { IconsContainerProps } from '.';

const AnsweredQuestionRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: Colors.TestResultsRowBackground,
  marginTop: '8px',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const Question = styled(Typography)({
  flex: 62,
  paddingRight: '112px',
  wordBreak: 'break-word',
});

Question.defaultProps = {
  variant: 'BodySmallRegular',
  color: Colors.Text.Heading,
};

const Answer = styled(Typography)({
  flex: 38,
  wordBreak: 'break-word',
});

Answer.defaultProps = {
  variant: 'BodySmallBoldOpen',
  color: Colors.Text.Heading,
};

const IconsContainer = styled(Box)<BoxProps & IconsContainerProps>((props) => ({
  width: '50px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: props.hasTwoItems ? 'space-between' : 'flex-end',
}));

export {
  AnsweredQuestionRow, Question, Answer, IconsContainer,
};

import { useState } from 'react';

export type ToastConfig = {
    isOpen: boolean;
    variant: 'success' | 'error' | 'warning';
    title?: string;
    subtitle?: string;
}

const initialToastConfig: ToastConfig = {
  isOpen: false,
  variant: 'success',
  title: '',
};

const useToast = () => {
  const [toastConfig, setToastConfig] = useState(initialToastConfig);

  const closeToast = () => {
    setToastConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return {
    toastConfig,
    closeToast,
    setToastConfig,
  };
};

export default useToast;

import React from 'react';
import AnswerShortProps from '.';
import { Container, Name, Value } from './styles';
import Colors from '../../../../theme/colors';

function AnswerShort({ label, value, strong = false } : AnswerShortProps) {
  return (
    <Container>
      <Name variant={!strong ? 'BodySmallRegular' : 'BodySmallSemiBold'} color={Colors.Text.Heading}>{label}</Name>
      <Value variant={!strong ? 'BodySmallRegular' : 'BodySmallSemiBold'} color={Colors.Text.Heading}>{value}</Value>
    </Container>
  );
}

export default AnswerShort;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Colors from '../../../theme/colors';

const Container = styled(Box)({
  display: 'flex',
  height: '270px',
  width: '100%',
  backgroundColor: Colors.Outline.Portal,
  justifyContent: 'center',
  alignItems: 'center',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  borderColor: Colors.Outline.Element,
  borderWidth: '1px',
  borderTopWidth: '0px',
});

export default Container;

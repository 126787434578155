export type BMIProps = {
  bmiValue: number;
};

export type BMIStatus = 'Underweight' | 'Healthy' | 'Overweight' | 'Obese';

export type StatusLabelProps = {
  status: BMIStatus;
};

export type BMIGraphParams = {
  graphPoints: Array<number>;
  resultLabel: string;
  resultValue: number;
};

export type BMIGraphColors = {
  valueLabelColor: string;
  valueLabelTextColor: string;
  colors: Array<string>;
};

export type BMIContainerProps = {
  status: BMIStatus
}

export { default as BMI } from './BMI';

import React from 'react';
import { ValueLabelProps } from '.';
import {
  Container,
  ValueContainer,
  PositionIndicator,
  ValueText,
} from './styles';

function ValueLabel({
  label,
  backgroundColor,
  textColor,
  offsetPercentage,
}: ValueLabelProps) {
  return (
    <Container leftOffset={offsetPercentage}>
      <ValueContainer color={backgroundColor}>
        <ValueText color={textColor}>{label}</ValueText>
      </ValueContainer>
      <PositionIndicator color={backgroundColor}>
        <svg width="12" height="6" strokeWidth={1} viewBox="0 0 12 6">
          <path
            stroke="#F4F4F4"
            strokeWidth={2}
            fill={backgroundColor}
            d="M 12.3197 -5.60772 L 7.12355 3.39228 C 6.35377 4.72561 4.42927 4.72561 3.65947 3.39227 L 3.65947
            3.39227 L -1.53668 -5.60773 C -2.30648 -6.94106 -1.34423 -8.60772 0.195366 -8.60773 L 0.195366
            -8.60773 L 10.5876 -8.60772 C 12.1272 -8.60772 13.0895 -6.94106 12.3197 -5.60772 Z"
          />
        </svg>
      </PositionIndicator>
    </Container>
  );
}

export default ValueLabel;

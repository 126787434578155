import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Colors from '../../../theme/colors';

const ContentContainer = styled(Box)({
  padding: '32px',
  width: '460px',
  display: 'flex',
  flexDirection: 'column',
});

const InputFieldsContainer = styled(Box)({
  marginBottom: '64px',
});

const HCPConsFieldContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '48px',
});

const ErrorText = styled(Typography)({
  textAlign: 'start',
  marginTop: '5px',
  color: Colors.Status.Error,
  fontSize: '0.75rem',
});

export {
  ContentContainer, InputFieldsContainer, HCPConsFieldContainer, ErrorText,
};

import { RegisterOptions } from 'react-hook-form';
import { EMAIL_REGEX } from '../../utilities/consts';

export type LoginFormData = {
    email: string;
    password: string;
  }

export type LoginInputRules = {
    email: RegisterOptions;
    password: RegisterOptions;
  }

export const loginInputRules: LoginInputRules = {
  email: {
    required: 'Required field',
    pattern: { value: EMAIL_REGEX, message: 'Invalid email format' },
  },
  password: { required: 'Required field' },
};

export const defaultFieldValues: LoginFormData = {
  email: '',
  password: '',
};

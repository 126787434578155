import React from 'react';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import { ResultStatusTableCellProps } from '.';
import { Circle, ContentContainer } from './styles';
import Colors from '../../../theme/colors';
import { getStatusColor, formatStatus } from '../../../utilities/utils';

function ResultStatusTableCell({ status } : ResultStatusTableCellProps) {
  return (
    <TableCell>
      <ContentContainer>
        <Circle color={getStatusColor(status)} />
        <Typography color={Colors.Text.Heading} variant="BodySmallRegular">
          {formatStatus(status)}
        </Typography>
      </ContentContainer>
    </TableCell>
  );
}

export default ResultStatusTableCell;

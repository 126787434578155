import { ModalStates } from '../../../hooks/useModal';

export type ModalActionHandlerProps = {
  modalState: ModalStates;
  submitDisabled: boolean;
  infoItems: Array<string>;
  handleSubmit: () => void;
  handleClose: () => void;
};

export { default as ModalActionsHandler } from './ModalActionsHandler';

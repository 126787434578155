/* eslint-disable camelcase */
import {
  BiologicalResult,
  ResultStatus,
} from '../pages/TestListPage/TestResultModal/BiologicalResults/BiologicalResult';

const totalCholesterol: BiologicalResult = {
  title: 'Total Cholesterol',
  significantPoints: {
    graphMin: 2,
    graphMax: 8,
    domainMin: 2.5,
    domainMax: 7,
    breakpoint: 5,
  },
  lowerIsHealthy: true,
  unit: '(mmol/L)',
};

const hdl: BiologicalResult = {
  title: 'HDL',
  significantPoints: {
    graphMin: 0,
    graphMax: 3,
    domainMin: 1,
    domainMax: 2.5,
    breakpoint: 1,
  },
  lowerIsHealthy: false,
  unit: '(mmol/L)',
};

const nonHdl: BiologicalResult = {
  title: 'Non-HDL',
  significantPoints: {
    graphMin: 2,
    graphMax: 6,
    domainMin: 2.5,
    domainMax: 5,
    breakpoint: 4,
  },
  lowerIsHealthy: true,
  unit: '(mmol/L)',
};

const ldl: BiologicalResult = {
  title: 'LDL',
  significantPoints: {
    graphMin: 0,
    graphMax: 7,
    domainMin: 0,
    domainMax: 7,
    breakpoint: 3,
  },
  lowerIsHealthy: true,
  unit: '(mmol/L)',
};

const totalHdlRatio: BiologicalResult = {
  title: 'Total Cholesterol/HDL',
  significantPoints: {
    graphMin: 2,
    graphMax: 8,
    domainMin: 2.5,
    domainMax: 7,
    breakpoint: 6,
  },
  lowerIsHealthy: true,
};

const triglycerides: BiologicalResult = {
  title: 'Triglycerides',
  significantPoints: {
    graphMin: 0,
    graphMax: 3,
    domainMin: 1,
    domainMax: 2.5,
    breakpoint: 2.3,
  },
  lowerIsHealthy: true,
  unit: '(mmol/L)',
};

export const BloodTestParamConfigs = {
  totalCholesterol,
  hdl,
  nonHdl,
  ldl,
  totalHdlRatio,
  triglycerides,
};

/**
 * @param min minimum value available in the domain
 * @param max maximum value available in the domain
 * @param thresholdExceeded is actual value outside the domain? value we receive here is normalized
 * to be in the domain, so this extra flag is needed to know where the actual value lies.
 * @param value value received
 * @returns value restrained to the provided domain limiters
 */

export const formatValue = (
  min: number,
  max: number,
  thresholdExceeded: boolean,
  value: number,
) => {
  if (thresholdExceeded) {
    return value === min ? `< ${min.toFixed(1)}` : `> ${max.toFixed(1)}`;
  }

  return value?.toFixed(1);
};

/**
 * For thresholded values, we provide info string which explains that the original value was
 * normalized to fit the allowed range.
 * @param min minimum value available in the domain
 * @param max maximum value available in the domain
 * @param thresholdExceeded is actual value outside the domain? value we receive here is normalized
 * to be in the domain, so this extra flag is needed to know where the actual value lies.
 * @param value value received
 * @returns info message for thresholded values, undefined otherwise
 */

export const getInfoMessage = (
  min: number,
  max: number,
  thresholdExceeded: boolean,
  value?: number,
) => {
  if (thresholdExceeded && value) {
    return value === min
      ? `Results below ${min} are shown as < ${min.toFixed(1)}`
      : `Results above ${max} are shown as > ${max.toFixed(1)}`;
  }

  return undefined;
};

/**
 * @param min minimum value available in the domain
 * @param lowerIsHealthy bool which determines if values bellow the
 * breakpoint are considered healthy
 * @param breakpoint point in the parameter domain which separates healthy and unhealthy values
 * @param thresholdExceeded is actual value outside the domain? value we receive here is normalized
 * to be in the domain, so this extra flag is needed to know where the actual value lies.
 * @param result value received from the blood test analysis
 * @returns string which determines if the value is Healthy,
 * Unhealthy (too high or too low) or not available
 */

export const getResultStatus = (
  min: number,
  lowerIsHealthy: boolean,
  breakpoint: number,
  thresholdExceeded: boolean,
  result?: number,
): ResultStatus => {
  // No result provided, status is not available
  if (result === undefined || result === null) {
    return 'N/A';
  }
  // Edge case in which min or max domain values are on the breakpoint and threshold is exceeded
  if (thresholdExceeded && result === breakpoint) {
    // if result equals min domain value, we get status for values lower than breakpoint
    if (result === min) {
      return lowerIsHealthy ? 'Healthy' : 'Low';
    }
    // otherwise we get status for values higher than breakpoint
    return lowerIsHealthy ? 'High' : 'Healthy';
  }
  if (lowerIsHealthy ? result <= breakpoint : result >= breakpoint) {
    return 'Healthy';
  }
  return lowerIsHealthy ? 'High' : 'Low';
};

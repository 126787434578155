import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ContentContainer = styled(Box)({
  padding: '32px',
});

const CircularProgressContainer = styled(Box)({
  padding: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export { ContentContainer, CircularProgressContainer };

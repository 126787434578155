import { RegisterOptions } from 'react-hook-form';
import { ModalStates } from '../../../hooks/useModal';
import { FullUser, UserRole, rolesConfig } from '../index';
import {
  NO_SPACE_ON_START_OR_END,
  EMAIL_REGEX,
} from '../../../utilities/consts';

type UserActionModalInputRules = {
  name: RegisterOptions;
  email: RegisterOptions;
  org: RegisterOptions;
};

export type UserActionModalFormFields = {
  name: string;
  active: boolean;
  email: string;
  org: string;
};

export type UserActionModalProps = {
  userRole: UserRole;
  modalState: ModalStates;
  selectedUser?: FullUser;
  addUser: (
    email: string,
    role: 'admin' | 'hcp',
    name: string,
    status: 'active' | 'inactive',
    orgId?: string
  ) => void;
  editUser: (
    orgId: string,
    name: string,
    status: 'active' | 'inactive'
  ) => void;
  resendInvitation: (userId: string, email: string) => void;
  onClose: () => void;
  onUserInteractionFailed: () => void;
};

export const getUserActionModalInputRules = (
  userRole: UserRole,
): UserActionModalInputRules => ({
  name: {
    required: 'Required field',
    pattern: {
      value: NO_SPACE_ON_START_OR_END,
      message: `${
        rolesConfig.get(userRole)?.roleTitle
      }'s name can't start or end with a space`,
    },
    minLength: {
      value: 2,
      message: 'Name must contain min 2 and max 50 characters',
    },
    maxLength: {
      value: 50,
      message: 'Name must contain min 2 and max 50 characters',
    },
  },
  email: {
    required: 'Required field',
    pattern: { value: EMAIL_REGEX, message: 'Invalid email format' },
  },
  org: {
    required: 'Required field',
  },
});

export const hcpinfoItems = [
  'Inactive HCP can not sign in to the mobile app.',
  'If the organization is inactive, their HCPs can not be set to active.',
];

export const EMAIL_UNAVAILABLE = 'A user with this email already exists in the system';

export const defaultFieldValues: UserActionModalFormFields = {
  name: '',
  active: true,
  email: '',
  org: '',
};

export { default as UserActionModal } from './UserActionModal';

import React from 'react';
import {
  AnsweredQuestionRow,
  Question,
  Answer,
  IconsContainer,
} from './styles';
import AnsweredQuestionProps from '.';
import Icon from '../../../../../components/Icon/Icon';
import Colors from '../../../../../theme/colors';
import { RiskFactor } from '../../../index';

function AnsweredQuestion({
  question,
  answer,
  influencesBiomarkers = false,
  riskFactor = null,
}: AnsweredQuestionProps) {
  return (
    <AnsweredQuestionRow>
      <Question>{question}</Question>
      <Answer>{answer ?? 'N/A'}</Answer>
      <IconsContainer
        hasTwoItems={influencesBiomarkers && riskFactor === RiskFactor.Caution}
      >
        {influencesBiomarkers ? (
          <Icon name="biomarkers" variant="default" />
        ) : null}
        {riskFactor === RiskFactor.Caution ? (
          <Icon name="warning" color={Colors.Status.Warning} />
        ) : null}
      </IconsContainer>
    </AnsweredQuestionRow>
  );
}

export default AnsweredQuestion;

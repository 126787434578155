import React, { useState } from 'react';

export type PaginationInfo = {
    page: number;
    pageCount: number,
}
const initialPaginationInfo: PaginationInfo = { page: 1, pageCount: 1 };

const usePagination = () => {
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>(initialPaginationInfo);

  const setPageCount = (pageCount: number) => {
    setPaginationInfo((prev) => ({
      ...prev,
      pageCount,
    }));
  };

  const onChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPaginationInfo((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const setPage = (value: number) => {
    setPaginationInfo((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const resetPagination = () => {
    setPaginationInfo(initialPaginationInfo);
  };

  return {
    initialPaginationInfo,
    paginationInfo,
    onChange,
    setPageCount,
    setPage,
    resetPagination,
  };
};

export default usePagination;

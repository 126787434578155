/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Typography from '@mui/material/Typography';
import Colors from '../../../theme/colors';
import { ValidationTextField } from '../../ValidationTextField';
import { Container, InputContainer } from './styles';
import { ModalTextInputProps } from '.';

function ModalTextInput({ label, inputProps, testId }: ModalTextInputProps) {
  return (
    <Container data-testid={testId}>
      <Typography variant="BodyMediumRegular" color={Colors.Text.Paragraph}>
        {label}
      </Typography>
      <InputContainer>
        <ValidationTextField {...inputProps} />
      </InputContainer>
    </Container>
  );
}

export default ModalTextInput;
